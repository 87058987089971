import { Component, OnInit, HostListener } from '@angular/core';

import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { appConfig } from '../../app.config';
import { ProductService } from 'src/app/services/product.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    currentUser: User;
    users: User[] = [];
    showNavbar = false;

    @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll(event) {
      if (event.srcElement.scrollTop > 11) {
        this.modalService.showNavbar = true;
      }
      if (event.srcElement.scrollTop <= 11) {
        this.modalService.showNavbar = false;
      }
    }

    constructor(private userService: UserService, private productService: ProductService, public modalService: ModalService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.loadAllUsers();
    }

    deleteUser(_id: string) {
        this.userService.delete(_id).subscribe(() => { this.loadAllUsers() });
    }

    private loadAllUsers() {
        // this.userService.getAll().subscribe(users => { this.users = users; });
    }

    releaseCatalogToMobile() {
        this.productService.releaseCatalogToMobile()
            .subscribe(
                data => {
                    
                },
                error => {
                   
                });
    }
}