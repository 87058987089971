import { Component, OnInit } from '@angular/core';

import { User } from '../../models/user';
import { Route, ActivatedRoute, Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProductService } from 'src/app/services/product.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
    templateUrl: 'user-view.component.html',
    styleUrls: ['./user-view.component.scss']
})

export class UserViewComponent implements OnInit {
    currentUser: User;
    products: any[] = [];
    productId: any;
    userId: any;
    currentProduct: any;
    currentLabel: any;
    currentViewUser: any;
    saving = false;
    loading = true;

    constructor(
        public userService: UserService,
        public modalService: ModalService,
        public productService: ProductService,
        public route: ActivatedRoute,
        public router: Router,
        public notification: NzNotificationService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.userId = this.route.snapshot.paramMap.get("id");
        this.loadUser(this.userId);
        this.userService.getCompanyTeamsLocal();
    }

    deleteUser(_id: string) {
        // this.userService.delete(_id).subscribe(() => { this.loadAllProducts() });
    } 

    loadUser(userId: any) {
        this.loading = true;
        this.userService.getUser(userId).subscribe(value => {
            console.log(value);
            this.currentViewUser = value;
            this.userService.currentViewUser = value;
            this.loading = false;
        });
    }

    activateProduct() {
        this.productService.activateProduct(this.currentProduct._id).subscribe(value => {
            console.log(value);
        });
    }

    deactivateProduct() {
        this.productService.deactivateProduct(this.currentProduct._id).subscribe(value => {
            console.log(value);
        });
    }

    onBack() {
        this.router.navigate([`settings/users`]);
    }

    updateUser() {
        this.saving = true;
        this.userService.updateUser(this.currentViewUser).subscribe(value => {
            console.log(value);
            this.saving = false;
            this.createNotification();
        });
    }

    createNotification(): void {
        this.notification.create(
            'success',
            `Changes saved`,
            '',
            { nzDuration: 2000 }
        );
    }

    deactivateUser() {
        this.currentViewUser.status = "DEACTIVATED";
        this.updateUser();
    }

    activateUser() {
        this.currentViewUser.status = "ACTIVE";
        this.updateUser();
    }

    compareFn = (o1: any, o2: any) => (o1 && o2 ? o1._id === o2._id : o1 === o2);

}