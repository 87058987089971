import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { appConfig } from '../app.config';
import { User } from '../models/user';

@Injectable()
export class ProductService {

    currentProduct: any;
    currentLabel: any;
    currentQuoteOption: any;
    currentProductCategory: any;

    productPriceTypes = [
        {
            _id: "5de6fe81e7179a0d18de7a09",
            priceType: "SQUAREFOOT",
            priceLabel: "per square foot"
        },
        {
            _id: "5e3370a9e7179a2f01161aca",
            priceType: "UNITEDINCH",
            priceLabel: "per united Inch"
        },
        {
            _id: "5de6fe8de7179a0d18de7a0a",
            priceType: "FLAT",
            priceLabel: "flat price per unit"
        }
    ]

    optionPriceTypes = [
        {
            _id: "5de6fe81e7179a0d18de7a09",
            priceType: "SQUAREFOOT",
            priceLabel: "per square foot"
        },
        {
            _id: "5e3370a9e7179a2f01161aca",
            priceType: "UNITEDINCH",
            priceLabel: "per united Inch"
        },
        {
            _id: "5de6fe8de7179a0d18de7a0a",
            priceType: "FLAT",
            priceLabel: "flat price per unit"
        },
        {
            _id: "5de6fed5e7179a0d18de7a18",
            priceType: "PERCENTAGE",
            priceLabel: "percent of base price"
        }
    ]

    optionPriceTypes2 = [
        {
            _id: "5de6fe97e7179a0d18de7a0c",
            priceType: "COUNT",
            priceLabel: "Count"
        }
    ]

    constructor(private http: HttpClient) { }

    getAll(currentUser: any) {
        return this.http.get<User[]>(appConfig.apiUrl + '/products?user=' + currentUser._id);
    }

    getById(_id: string) {
        return this.http.get(appConfig.apiUrl + '/products/getProduct/' + _id);
    }

    create(newProduct: any, creator: any) {
        console.log(newProduct);
        return this.http.post(appConfig.apiUrl + '/products/create', { newProduct: newProduct, creator: creator });
    }

    update(user: User) {
        return this.http.put(appConfig.apiUrl + '/users/' + user._id, user);
    }

    delete(_id: string) {
        return this.http.delete(appConfig.apiUrl + '/users/' + _id);
    }

    changePassword(data: any, token: any) {
        return this.http.post(appConfig.apiUrl + '/users/changePassword', { 'newPassword': data.newPassword, 'token': token });
    }

    setImage(productId: any, image: any) {
        return this.http.post(appConfig.apiUrl + '/products/setProductImage', { product: productId, image: image });
    }

    uploadImage(image: any) {
        return this.http.post(appConfig.apiUrl + '/products/uploadImage', { image: image });
    }

    addCustomizations(productId: any, customizationArray: any) {
        return this.http.post(appConfig.apiUrl + '/products/addCustomizations', { product: productId, customizations: customizationArray });
    }

    removeCustomization(productId: any, customization: any) {
        return this.http.post(appConfig.apiUrl + '/products/removeCustomization', { product: productId, customization: customization });
    }

    activateProduct(productId: any) {
        return this.http.post(appConfig.apiUrl + '/products/activateProduct', { product: productId });
    }

    deactivateProduct(productId: any) {
        return this.http.post(appConfig.apiUrl + '/products/deactivateProduct', { product: productId });
    }

    getObjectPricing(productId: any) {
        return this.http.get(appConfig.apiUrl + '/products/getProductPricing/' + productId);
    }

    getPriceTypes() {
        return this.http.get(appConfig.apiUrl + '/products/getPriceTypes/');
    }

    getAllImages() {
        return this.http.get(appConfig.apiUrl + '/products/getAllImages/');
    }

    savePricing(objectId: any, pricingObject: any) {
        console.log(pricingObject);
        let bodyObject = {
            objectId: objectId,
            pricingObject: pricingObject
        }
        console.log(bodyObject);
        return this.http.post(appConfig.apiUrl + '/products/savePricing', bodyObject);
    }

    saveMaxSize(productId: any, maxSizeObject: any) {
        return this.http.post(appConfig.apiUrl + '/products/saveMaxSize', { productId: productId, maxSizeObject: maxSizeObject });
    }

    saveMinSize(productId: any, minSizeObject: any) {
        return this.http.post(appConfig.apiUrl + '/products/saveMinSize', { productId: productId, minSizeObject: minSizeObject });
    }

    saveLabel(labelObject: any) {
        return this.http.post(appConfig.apiUrl + '/products/saveLabel', { labelObject: labelObject });
    }

    getCompanyLabels() {
        return this.http.get(appConfig.apiUrl + '/products/getCompanyLabels/');
    }

    getOneLabel(_id: string) {
        return this.http.get(appConfig.apiUrl + '/products/getOneLabel/' + _id);
    }

    saveLabelOption(parentObjectId: any, optionObject: any) {
        console.log(parentObjectId);
        console.log(optionObject);
        return this.http.post(appConfig.apiUrl + '/products/saveLabelOption', { parentObjectId: parentObjectId, optionObject: optionObject });
    }

    addLabelJunction(productId: any, labelJunctionObject: any) {
        return this.http.post(appConfig.apiUrl + '/products/addLabelJunction', { productId: productId, labelJunctionObject: labelJunctionObject });
    }

    removeLabel(productId: any, label: any) {
        return this.http.post(appConfig.apiUrl + '/products/removeLabelJunction', { productId: productId, label: label });
    }

    getAllQuoteOptions() {
        return this.http.get<User[]>(appConfig.apiUrl + '/products/getAllQuoteOptions');
    }

    createQuoteOption(quoteOptionObject: any) {
        return this.http.post(appConfig.apiUrl + '/products/createQuoteOption', { quoteOptionObject: quoteOptionObject });
    }

    getQuoteOption(_id: string) {
        return this.http.get(appConfig.apiUrl + '/products/getQuoteOption/' + _id);
    }

    saveProductCategory(productCategoryObject: any) {
        return this.http.post(appConfig.apiUrl + '/products/createProductCategory', { productCategoryObject: productCategoryObject });
    }

    getCompanyProductCategories() {
        return this.http.get(appConfig.apiUrl + '/products/getCompanyProductCategories');
    }

    saveCustomizationCategory(customizationCategoryObject: any) {
        return this.http.post(appConfig.apiUrl + '/products/createCustomizationCategory', { customizationCategoryObject: customizationCategoryObject });
    }

    getCompanyCustomizationCategories() {
        return this.http.get(appConfig.apiUrl + '/products/getCompanyCustomizationCategories');
    }

    getProductTypes() {
        return this.http.get(appConfig.apiUrl + '/products/getProductTypes');
    }

    releaseCatalogToMobile() {
        return this.http.get(appConfig.apiUrl + '/products/releaseCatalogToMobile');
    }

    getCompanyLabels2() {
        return this.http.get(appConfig.apiUrl + '/products/getCompanyLabels2');
    }

    saveProductObject(productObject: any) {
        return this.http.post(appConfig.apiUrl + '/products/createProductObject', { productObject: productObject });
    }

    getProductsInCategory(categoryId) {
        return this.http.get(appConfig.apiUrl + '/products/getProductsInCategory/' + categoryId);
    }

    duplicateProduct(productObject: any) {
        return this.http.post(appConfig.apiUrl + '/products/duplicateProduct', { productObject: productObject });
    }

}