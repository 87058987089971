import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { ProductService } from 'src/app/services/product.service';
import { ModalService } from 'src/app/services/modal.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { VirtualTimeScheduler } from 'rxjs';


@Component({
    templateUrl: 'edit-user-permissions.component.html',
    selector: 'app-edit-user-permissions',
})

export class EditUserPermissionComponent {
    base64textString: any;
    model: any = {
        image: this.base64textString
    };
    loading = false;
    currentUser: any;
    imageString: any;
    saving = false;

    constructor(
        public router: Router,
        public userService: UserService,
        public alertService: AlertService,
        public productService: ProductService,
        public modalService: ModalService,
        private notification: NzNotificationService
        ) { 
            this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
            console.log(this.currentUser);
        }

    saveLabel() {
        this.loading = true;
        if (this.modalService.currentLabel) {
            this.model = this.modalService.currentLabel;
        }
        this.productService.saveLabel(this.model)
            .subscribe(
                data => {
                    this.alertService.success('label saved', true);
                    this.loading = false;
                    this.router.navigateByUrl('/label/'+data['_id']);
                    this.modalService.closeNewLabel();
                    this.createNotificationCreated();
                    this.model = {
                        image: this.base64textString
                    };
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    handleFileSelect(evt: any){
        var files = evt.target.files;
        var file = files[0];
      
      if (files && file) {
          var reader = new FileReader();
  
          reader.onload =this._handleReaderLoaded.bind(this);
  
          reader.readAsBinaryString(file);
      }
    }
    
    _handleReaderLoaded(readerEvt: any) {
       var binaryString = readerEvt.target.result;
              this.base64textString = btoa(binaryString);
              this.base64textString = 'data:image/png;base64,' + this.base64textString;
              console.log(btoa(binaryString));
      }

    cancelNewProduct() {
        this.modalService.newProductOpen = false;
    }

    uploadImage() {
        this.productService.setImage(this.productService.currentProduct._id, this.base64textString).subscribe();
    }

    createNotificationCreated(): void {
        this.notification.create(
            'success',
            'Permissions saved',
            '',
            { nzDuration: 3000 }
        );
    }

    saveUserPermissions() {
        this.saving = true;
        this.userService.saveUserPermissions(this.userService.currentViewUser.permissionsDisplay, this.userService.currentViewUser._id, this.userService.currentViewUser.company._id).subscribe(value => {
            console.log(value);
            this.modalService.closeEditUserPermissionsMenu();
            this.createNotificationCreated();
            this.saving = false;
        });
    }
}
