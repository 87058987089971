import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

import { normalizeGenFileSuffix } from '@angular/compiler/src/aot/util';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { ProductService } from 'src/app/services/product.service';
import { ModalService } from 'src/app/services/modal.service';
import { CustomizationService } from 'src/app/services/customization.service';

@Component({
    templateUrl: 'pricing-editor.component.html',
    selector: 'app-pricing-editor',
})

export class PricingEditorComponent {
    priceType = 'STANDARD';
    saving = false;
    base64textString: any;
    model: any = {
        image: this.base64textString
    };
    loaded = false;
    currentUser: any;
    flatPricing: any = {
        price: 0,
        priceType: {}
    };
    tieredPricing: any = {
        tierThresholdUnit: {},
        tiers: [
            {
                max: 1,
                price: 0,
                priceType: {}
            },
            {
                min: 1,
                price: 0,
                priceType: {}
            }
        ]
    }

    newTierTemplate: any = {
        min: 1,
        price: 0,
        priceType: {}
    }

    priceTypes: any = [];
    tierThresholdUnits: any = [];
    tieredPricingEnabled = false;

    priceToSend = {};

    objectId: any = null;

    constructor(
        public router: Router,
        public userService: UserService,
        public alertService: AlertService,
        public productService: ProductService,
        public modalService: ModalService,
        public customizationService: CustomizationService,
        private message: NzMessageService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log(this.currentUser);
    }

    ngOnInit() {
        // this.getObjectPricing(this.productService.currentProduct._id);
        this.loaded = false;
        this.getPriceTypes();
        this.objectId = this.modalService.currentPricingObject._id;
        console.log(this.objectId);
        let pricing = null

        if (this.modalService.currentPricingObject.price) {
            pricing = this.modalService.currentPricingObject.price;
        }

        if (pricing) {
            console.log(pricing);
            this.priceType = pricing.type;
            if (pricing['tiers']) {
                this.tieredPricing = pricing;
            } else {
                this.flatPricing = pricing;
                console.log(this.flatPricing);
            }
        } else {
            this.flatPricing = {};
        }


        this.loaded = true;
    }

    compareFn = (o1: any, o2: any) => (o1 && o2 ? o1._id === o2._id : o1 === o2);

    // save() {
    //     this.loading = true;
    //     this.customizationService.create(this.model, this.currentUser)
    //         .subscribe(
    //             data => {
    //                 this.alertService.success('Registration successful', true);
    //                 this.loading = false;
    //                 this.modalService.closeNewCustomization();
    //             },
    //             error => {
    //                 this.alertService.error(error);
    //                 this.loading = false;
    //             });
    // }

    private getObjectPricing(objectId: any) {
        this.loaded = false;
        this.productService.getObjectPricing(objectId).subscribe(pricing => {
            console.log(pricing);
            if (pricing['_id']) {
                if (pricing['tiers'][1]) {
                    this.tieredPricing = pricing;
                    this.tieredPricingEnabled = true;
                } else {
                    this.flatPricing = pricing;
                }
            }
            this.loaded = true;
        });
    }

    checkIfAdded(customization: any) {
        let value = false;
        for (let currentCust of this.productService.currentProduct.customizations) {
            if (currentCust._id == customization._id) {
                return true;
            } else {
                value = false;
            }
        }
        return value;
    }

    // toggleCustomization(customization: any) {
    //     console.log(customization);
    //     if (customization.isChecked) {
    //         for (let index in this.customizationsToAdd) {
    //             let currentCust = this.customizationsToAdd[index];
    //             if (currentCust._id == customization._id) {
    //                 this.customizationsToAdd.splice(index, 1);
    //                 console.log(this.customizationsToAdd);
    //                 customization.isChecked = false;
    //             }
    //         }
    //     } else {
    //         customization.isChecked = true;
    //         this.customizationsToAdd.push(customization);
    //         console.log(this.customizationsToAdd);
    //     }
    // }

    // addCustomizations() {
    //     this.productService.addCustomizations(this.productService.currentProduct._id, this.customizationsToAdd).subscribe(value => {
    //         console.log(value);
    //     })
    // }

    getPriceTypes() {
        this.productService.getPriceTypes().subscribe(dataObject => {
            this.priceTypes = dataObject['priceTypes'];
            this.tierThresholdUnits = dataObject['tierThresholdUnits'];
            console.log(dataObject);
        });
    }

    savePricing() {
        this.saving = true;
        if (this.priceType == 'TIERED' || this.priceType == 'MATRIX') {
            this.priceToSend = this.tieredPricing;
            if (this.flatPricing._id) {
                this.priceToSend['_id'] = this.flatPricing._id
            }
            this.priceToSend['type'] = this.priceType;
            console.log(this.tieredPricing);

            // Convert percentage to decimal
            for (let [index, tier] of this.tieredPricing.tiers.entries()) {
                if (tier['priceType'].priceType == 'PERCENTAGE') {
                    tier['price'] == this.flatPricing.price / 100;
                }

                if (index != 0) {
                    if (this.priceType != 'MATRIX') {
                        tier.min = this.tieredPricing.tiers[index-1].max;
                    }
                }

                tier.sortPosition = index;
            }
        } else if (this.priceType == "STANDARD") {
            this.priceToSend = this.flatPricing;
            if (this.tieredPricing._id) {
                this.priceToSend['_id'] = this.tieredPricing._id
            }
            this.priceToSend['type'] = 'STANDARD';

            // Convert percentage to decimal
            if (this.flatPricing.priceType.priceType == 'PERCENTAGE') {
                this.flatPricing.price == this.flatPricing.price / 100
            }
        }
        console.log(this.priceToSend);
        this.productService.savePricing(this.objectId, this.priceToSend).subscribe(response => {
            this.modalService.currentPricingObject.price = this.priceToSend;
            this.saving = false;
            this.createMessage();
            this.modalService.closePricingEditor();
            console.log(response);
        });
    }

    addTier() {
        this.tieredPricing.tiers.push(JSON.parse(JSON.stringify(this.newTierTemplate)));
        this.tieredPricing.tiers[this.tieredPricing.tiers.length - 2].max = 1;
    }

    removeLastTier() {
        this.tieredPricing.tiers.pop();
        this.tieredPricing.tiers[this.tieredPricing.tiers.length - 1].max = null;
    }

    createMessage(): void {
        this.message.create('success', `Pricing saved`);
    }
}
