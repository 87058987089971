import { Component, OnInit } from '@angular/core';

import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { appConfig } from '../../app.config';
import { ProductService } from 'src/app/services/product.service';

@Component({
    templateUrl: 'admin-home.component.html',
    styleUrls: ['./admin-home.component.scss']
})

export class AdminHomeComponent implements OnInit {
    currentUser: User;
    users: User[] = [];

    constructor(private userService: UserService, private productService: ProductService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.loadAllUsers();
    }

    deleteUser(_id: string) {
        this.userService.delete(_id).subscribe(() => { this.loadAllUsers() });
    }

    private loadAllUsers() {
        // this.userService.getAll().subscribe(users => { this.users = users; });
    }

    releaseCatalogToMobile() {
        this.productService.releaseCatalogToMobile()
            .subscribe(
                data => {
                    
                },
                error => {
                   
                });
    }
}