import { Component, OnInit } from '@angular/core';

import { User } from '../../models/user';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { CompanyService } from 'src/app/services/company.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
    templateUrl: 'admin-companies.component.html'
})

export class AdminCompaniesComponent implements OnInit {
    currentUser: User;
    companies: any = [];
    tablePageSize: any = 10;
    tablePageIndex: any;
    listOfDisplayData: any;
    searchTerm: any;
    originalCompanies: any = [];

    constructor(
        private userService: UserService,
        public modalService: ModalService,
        public companyService: CompanyService,
        public productService: ProductService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.loadAllCompanies();
    }

    deleteUser(_id: string) {
        // this.userService.delete(_id).subscribe(() => { this.loadAllUsers() });
    }

    private loadAllCompanies() {
        this.productService.getCompanyLabels2().subscribe(companies => {
            console.log(companies); 
            this.originalCompanies = companies;
            this.companies = companies; 
        });
    }

    newCompany() {
        this.modalService.openNewCompany();
    }

    currentPageDataChange(event) {
        console.log(event);
        this.listOfDisplayData = event;
    }

    currentPageSizeChange(event) {
        this.tablePageSize = event;
    }

    filterItems(searchTerm) {
        this.companies = this.originalCompanies.filter(item => {
            return item.displayName.toString().toLowerCase().indexOf(this.searchTerm.toString().toLowerCase()) > -1;
        });
    }
}