import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { ProductService } from 'src/app/services/product.service';
import { ModalService } from 'src/app/services/modal.service';
import { CustomizationService } from 'src/app/services/customization.service';

@Component({
    templateUrl: 'labels.component.html',
    selector: 'app-labels',
})

export class LabelsComponent {
    base64textString: any;
    model: any = {
        image: this.base64textString
    };
    loading = false;
    currentUser: any;
    pricing: any;
    customizationsToAdd: any = [];
    priceTypes: any = [];
    labels: any = [];
    originalLabels: any = [];
    searchTerm: any = '';
    listOfDisplayData: any;
    tablePageSize: any;

    constructor(
        public router: Router,
        public userService: UserService,
        public alertService: AlertService,
        public productService: ProductService,
        public modalService: ModalService,
        public customizationService: CustomizationService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log(this.currentUser);
    }

    ngOnInit() {
        this.productService.getCompanyLabels().subscribe(value => {
            this.originalLabels = value;
            this.labels = value;
            console.log(this.labels);
        })
    }

    save() {
        this.loading = true;
        this.customizationService.create(this.model)
            .subscribe(
                data => {
                    this.alertService.success('Registration successful', true);
                    this.loading = false;
                    this.modalService.closeNewCustomization();
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    handleFileSelect(evt: any) {
        var files = evt.target.files;
        var file = files[0];

        if (files && file) {
            var reader = new FileReader();

            reader.onload = this._handleReaderLoaded.bind(this);

            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoaded(readerEvt: any) {
        var binaryString = readerEvt.target.result;
        this.base64textString = btoa(binaryString);
        console.log(btoa(binaryString));
    }

    private getObjectPricing(objectId: any) {
        this.productService.getObjectPricing(objectId).subscribe(pricing => {
            this.pricing = pricing;
            console.log(this.pricing);
        });
    }

    checkIfAdded(customization: any) {
        let value = false;
        for (let currentCust of this.productService.currentProduct.customizations) {
            if (currentCust._id == customization._id) {
                return true;
            } else {
                value = false;
            }
        }
        return value;
    }

    toggleCustomization(customization: any) {
        console.log(customization);
        if (customization.isChecked) {
            for (let index in this.customizationsToAdd) {
                let currentCust = this.customizationsToAdd[index];
                if (currentCust._id == customization._id) {
                    this.customizationsToAdd.splice(index, 1);
                    console.log(this.customizationsToAdd);
                    customization.isChecked = false;
                } 
            }
        } else {
            customization.isChecked = true;
            this.customizationsToAdd.push(customization);
            console.log(this.customizationsToAdd);
        }
    }

    addCustomizations() {
        this.productService.addCustomizations(this.productService.currentProduct._id, this.customizationsToAdd).subscribe(value => {
            console.log(value);
        })
    }

    private getPriceTypes() {
        this.productService.getPriceTypes().subscribe(priceTypes => {
            this.priceTypes = priceTypes;
            console.log(priceTypes);
        });
    }

    currentPageDataChange(event) {
        console.log(event);
        this.listOfDisplayData = event;
    }

    currentPageSizeChange(event) {
        this.tablePageSize = event;
    }

    filterItems(searchTerm) {
        this.labels = this.originalLabels.filter(item => {
            return item.referenceName.toString().toLowerCase().indexOf(this.searchTerm.toString().toLowerCase()) > -1;
        });
    }
}
