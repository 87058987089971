import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { ModalService } from 'src/app/services/modal.service';
import { NzNotificationService } from 'ng-zorro-antd';


@Component({
    templateUrl: 'new-user.component.html',
    selector: 'app-new-user',
})

export class NewUserComponent {
    model: any = {
        password: "password"
    };
    loading = false;
    currentUser: any;

    newModel = [
        {
            password: "password",
            firstName: "",
            lastName: "",
            email: "",
            admin: false
        },
        {
            password: "password",
            firstName: "",
            lastName: "",
            email: "",
            admin: false
        },
        {
            password: "password",
            firstName: "",
            lastName: "",
            email: "",
            admin: false
        }
    ]

    newUserObject = {
        password: "password",
        firstName: "",
        lastName: "",
        email: "",
        admin: false
    }

    constructor(
        public router: Router,
        public userService: UserService,
        public alertService: AlertService,
        public modalService: ModalService,
        public notification: NzNotificationService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log(this.currentUser);
    }

    register() {
        this.loading = true;
        this.newModel = this.newModel.filter(function( obj ) {
            return obj.firstName !== '' && obj.lastName !== '' && obj.email !== '';
        });
        this.userService.create(this.newModel, this.currentUser)
            .subscribe(
                data => {
                    console.log(data);
                    this.modalService.closeNewUser();
                    this.createNotification();
                    this.userService.loadAllUsers();
                    this.newModel = [
                        {
                            password: "password",
                            firstName: "",
                            lastName: "",
                            email: "",
                            admin: false
                        },
                        {
                            password: "password",
                            firstName: "",
                            lastName: "",
                            email: "",
                            admin: false
                        },
                        {
                            password: "password",
                            firstName: "",
                            lastName: "",
                            email: "",
                            admin: false
                        }
                    ]
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    createNotification(): void {
        this.notification.create(
            'success',
            `Invitations sent!`,
            '',
            { nzDuration: 2000 }
        );
    }

    addUser() {
        this.newModel.push(JSON.parse(JSON.stringify(this.newUserObject)));
    }
}
