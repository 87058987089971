import { Component, OnInit } from '@angular/core';

import { User } from '../../models/user';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { IntegrationService } from 'src/app/services/integration.service';
import { CompanyService } from 'src/app/services/company.service';

@Component({
    templateUrl: 'settings-company-info.component.html',
    styleUrls: ['./settings-company-info.component.scss']

})

export class SettingsCompanyInfoComponent implements OnInit {
    currentUser: User;
    users: any[] = [];
    tablePageSize: any = 10;
    tablePageIndex: any;
    listOfDisplayData: any;
    searchTerm: any;
    originalUsers: any[] = [];
    integrations: any;
    loading = true;

    constructor(
        public userService: UserService,
        public modalService: ModalService,
        public integrationService: IntegrationService,
        public companyService: CompanyService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.getCompany(this.userService.currentUser.company._id);
    }

    deleteUser(_id: string) {
        this.userService.delete(_id).subscribe(() => { this.loadAllUsers() });
    }

    private loadAllUsers() {
        this.userService.getAll(this.currentUser).subscribe(users => { 
            this.originalUsers = users;
            this.users = users; 
        });
    }

    newUser() {
        this.modalService.newUserOpen = true;
    }

    editCompanyInfo() {
        this.modalService.openEditCompanyInfo();
    }

    currentPageDataChange(event) {
        console.log(event);
        this.listOfDisplayData = event;
    }

    currentPageSizeChange(event) {
        this.tablePageSize = event;
    }

    filterItems(searchTerm) {
        this.users = this.originalUsers.filter(item => {
            return item.email.toString().toLowerCase().indexOf(this.searchTerm.toString().toLowerCase()) > -1;
        });
    }

    openIntegrationMenu() {
        this.modalService.openIntegrationMenu();
    }

    loadIntegrations() {
        this.integrationService.getCompanyIntegrations().subscribe(value => {
            console.log(value);
            this.integrations = value;
        });
    }

    getCompany(companyId) {
        console.log(companyId);
        this.companyService.getCompany(companyId).subscribe(value => {
            console.log(value);
            if (!value['address']) {
                value['address'] = {};
            }

            if (!value['contactInfo']) {
                value['contactInfo'] = {};
            }
            this.companyService.currentCompany = value;
            this.loading = false;
        });
    }

    openMediaManager() {
        this.modalService.openMediaManager('COMPANY');
    }

    setCompanySettings() {
        this.companyService.setCompanySettings(this.companyService.currentCompany).subscribe(value => {
            console.log(value);
            this.integrations = value;
        });
    }
}