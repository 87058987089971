import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { ModalService } from 'src/app/services/modal.service';
import { NzNotificationService } from 'ng-zorro-antd';


@Component({
    templateUrl: 'new-team.component.html',
    selector: 'app-new-team',
})

export class NewTeamComponent {
    currentUser: any;

    currentTeam: any = {
        displayName: '',
        company: null
    }

    constructor(
        public router: Router,
        public userService: UserService,
        public alertService: AlertService,
        public modalService: ModalService,
        public notification: NzNotificationService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log(this.currentUser);
    }

    saveTeam() {
        this.currentTeam.company = this.currentUser.company._id;
        this.userService.saveTeam(this.currentTeam)
            .subscribe(
                data => {
                    console.log(data);
                    this.createNotification();
                    this.modalService.closeNewTeam();
                    this.userService.getCompanyTeamsLocal();
                });
    }

    createNotification(): void {
        this.notification.create(
            'success',
            `Team created!`,
            '',
            { nzDuration: 2000 }
        );
    }
}
