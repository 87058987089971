import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProductService } from 'src/app/services/product.service';
import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
    templateUrl: 'min-size-editor.component.html',
    selector: 'app-min-size-editor',
})

export class MinSizeEditorComponent implements OnInit {
    model: any = {

    };
    loading = false;
    currentUser: any;

    constructor(
        public router: Router,
        public userService: UserService,
        public alertService: AlertService,
        public modalService: ModalService,
        public productService: ProductService,
        private message: NzMessageService

    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log(this.currentUser);
    }

    ngOnInit() {
        if (this.productService.currentProduct.minSize) {
            this.model.value = this.productService.currentProduct.minSize.value;
            this.model._id = this.productService.currentProduct.minSize._id;
        }
    }

    save() {
        this.loading = true;
        this.productService.saveMinSize(this.productService.currentProduct._id, this.model)
            .subscribe(
                data => {
                    this.alertService.success('Minimum Size Saved', true);
                    this.loading = false;
                    this.productService.currentProduct.minSize = data;
                    this.createMessage();
                    this.modalService.closeMinSizeEditor();
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    createMessage(): void {
        this.message.create('success', `Minimum size saved`);
    }
}
