import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { ProductService } from 'src/app/services/product.service';
import { ModalService } from 'src/app/services/modal.service';
import { CustomizationService } from 'src/app/services/customization.service';


@Component({
    templateUrl: 'new-customization.component.html',
    selector: 'app-new-customization',
})

export class NewCustomizationComponent implements OnInit {
    base64textString: any;
    model: any = {
        image: this.base64textString
    };
    loading = false;
    currentUser: any;
    customizationTypes: any = [];
    editing = false;
    customizationCategories: any;

    constructor(
        public router: Router,
        public userService: UserService,
        public alertService: AlertService,
        public productService: ProductService,
        public modalService: ModalService,
        public customizationService: CustomizationService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log(this.currentUser);
    }

    ngOnInit() {
        this.getCustomizationTypes();
        console.log(this.modalService.currentOption);
        if (this.modalService.currentOption) {
            this.model = JSON.parse(JSON.stringify(this.modalService.currentOption));
            this.editing = true;
        } else {
            this.editing = false;
        }

        this.productService.getCompanyCustomizationCategories().subscribe(value => {
            this.customizationCategories = value;
            console.log(this.customizationCategories)
        });
    };

    ngAfterViewInit() {
        console.log(this.modalService.currentOption);
        if (this.modalService.currentOption) {
            this.model = this.modalService.currentOption;
            this.editing = true;
        } else {
            this.editing = false;
        }
    };

    save() {
        this.loading = true;
        let objectToSend = null;

        if (!this.modalService.optionEditing) {
            objectToSend = this.model;
        } else {
            objectToSend = this.modalService.currentOption;
        }

        this.customizationService.create(objectToSend)
            .subscribe(
                data => {
                    this.loading = false;
                    this.router.navigateByUrl('/option/' + data['_id']);
                    this.modalService.closeNewCustomization();
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });

    }

    handleFileSelect(evt: any) {
        var files = evt.target.files;
        var file = files[0];

        if (files && file) {
            var reader = new FileReader();

            reader.onload = this._handleReaderLoaded.bind(this);

            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoaded(readerEvt: any) {
        var binaryString = readerEvt.target.result;
        this.base64textString = btoa(binaryString);
        console.log(btoa(binaryString));
    }

    private getCustomizationTypes() {
        this.customizationService.getCustomizationTypes().subscribe(dataObject => {
            this.customizationTypes = dataObject;
            console.log(dataObject);
        });
    }

    compareFn = (o1: any, o2: any) => (o1 && o2 ? o1._id === o2._id : o1 === o2);

}
