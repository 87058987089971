import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { appConfig } from '../app.config';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationService {
    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    login(email: string, password: string) {
        return this.http.post<any>(appConfig.apiUrl + '/users/authenticate', { credentials: { email: email, password: password } })
            .pipe(user => {
                return user;
            });
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.router.navigateByUrl('/login');
    }

    createCompanyFromSignup(signupObject) {
        return this.http.post<any>(appConfig.apiUrl + '/users/createCompanyFromSignup', { signupObject: signupObject })
            .pipe(user => {
                return user;
            });
    }
}