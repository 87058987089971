import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { ProductService } from 'src/app/services/product.service';
import { ModalService } from 'src/app/services/modal.service';
import { CompanyService } from 'src/app/services/company.service';


@Component({
    templateUrl: 'edit-company-info.component.html',
    selector: 'app-edit-company-info',
})

export class EditCompanyInfoComponent {
    base64textString: any;
    model: any = {
        image: this.base64textString
    };
    loading = false;
    currentUser: any;
    imageString: any;
    productCategories: any = null;
    productTypes: any = null;

    constructor(
        public router: Router,
        public userService: UserService,
        public alertService: AlertService,
        public productService: ProductService,
        public modalService: ModalService,
        public companyService: CompanyService
        ) { 
            this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
            console.log(this.currentUser);
        }

    ngOnInit() {
        this.productService.getCompanyProductCategories().subscribe(value => {
            this.productCategories = value;
            console.log(this.productCategories)
        });

        this.productService.getProductTypes().subscribe(value => {
            this.productTypes = value;
            console.log(this.productTypes);
        });
    }

    compareFn = (o1: any, o2: any) => (o1 && o2 ? o1._id === o2._id : o1 === o2);

    saveProduct() {
        this.loading = true;
        if (this.productService.currentProduct) {
            this.model.id = this.productService.currentProduct;
        }
        this.companyService.update(this.userService.currentUser.company)
            .subscribe(
                data => {
                    this.alertService.success('Registration successful', true);
                    this.loading = false;
                    this.modalService.closeEditCompanyInfo();
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    handleFileSelect(evt: any){
        var files = evt.target.files;
        var file = files[0];
      
      if (files && file) {
          var reader = new FileReader();
  
          reader.onload =this._handleReaderLoaded.bind(this);
  
          reader.readAsBinaryString(file);
      }
    }
    
    _handleReaderLoaded(readerEvt: any) {
       var binaryString = readerEvt.target.result;
              this.base64textString = btoa(binaryString);
              this.base64textString = 'data:image/png;base64,' + this.base64textString;
              console.log(btoa(binaryString));
      }

    closeWindow() {
        this.modalService.closeEditProduct();
    }

    uploadImage() {
        this.productService.setImage(this.productService.currentProduct._id, this.base64textString).subscribe();
    }
}
