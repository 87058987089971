import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProductService } from 'src/app/services/product.service';
import { NzMessageService } from 'ng-zorro-antd/message';



@Component({
    templateUrl: 'max-size-editor.component.html',
    selector: 'app-max-size-editor',
})

export class MaxSizeEditorComponent implements OnInit {
    model: any = {

    };
    loading = false;
    currentUser: any;

    constructor(
        public router: Router,
        public userService: UserService,
        public alertService: AlertService,
        public modalService: ModalService,
        public productService: ProductService,
        private message: NzMessageService

    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log(this.currentUser);
    }

    ngOnInit() {
        if (this.productService.currentProduct.maxSize) {
            this.model.maxWidth = this.productService.currentProduct.maxSize.maxWidth;
            this.model.maxHeight = this.productService.currentProduct.maxSize.maxHeight;
            this.model._id = this.productService.currentProduct.maxSize._id;
        }
    }

    save() {
        this.loading = true;
        this.productService.saveMaxSize(this.productService.currentProduct._id, this.model)
            .subscribe(
                data => {
                    this.alertService.success('Maximum Size Saved', true);
                    this.loading = false;
                    this.productService.currentProduct.maxSize = data;
                    this.createMessage();
                    this.modalService.closeMaxSizeEditor();
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    createMessage(): void {
        this.message.create('success', `Maximum size saved`);
    }
}
