import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { appConfig } from '../app.config';
import { User } from '../models/user';

@Injectable()
export class CompanyService {
    currentUser = JSON.parse(localStorage.getItem('currentUser'));
    currentCustomer: any;
    currentQuote: any;
    getAllCustomers = true;
    docSettingsObject:any = { 
        trustBadges: [],
        footerMessage: '',
        contracts: [],
        logo: null
    }
    currentCompany: any;

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get(appConfig.apiUrl + '/products/getAllCompanies');
    }

    getById(_id: string) {
        return this.http.get(appConfig.apiUrl + '/users/' + _id);
    }

    create(newCompany) {
        return this.http.post(appConfig.apiUrl + '/companies/newCompany', newCompany);
    }

    update(company) {
        return this.http.post(appConfig.apiUrl + '/companies/updateCompany', company);
    }

    delete(_id: string) {
        return this.http.delete(appConfig.apiUrl + '/users/' + _id);
    }

    changePassword(data: any, token: any) {
        return this.http.post(appConfig.apiUrl + '/users/changePassword', {'newPassword': data.newPassword, 'token': token});
    }

    getCompanyQuotes(pageNumber: any) {
        return this.http.get(appConfig.apiUrl + `/quotes/getCompanyQuotes?pageNumber=${pageNumber}`);
    }

    getCompanyCustomers(pageNumber) {
        console.log(this.getAllCustomers);
        return this.http.get(appConfig.apiUrl + `/customers/getCompanyCustomers?pageNumber=${pageNumber}`);
    }

    getOneCustomer(customerId) {
        return this.http.get(appConfig.apiUrl + `/quotes/getCustomerWithQuotes?customer=${customerId}`);
    }

    getOneQuote(quoteId) {
        return this.http.get(appConfig.apiUrl + `/quotes/getQuote?quote=${quoteId}`);
    }

    getDashboardAnalytics() {
        return this.http.get(appConfig.apiUrl + `/quotes/getDashboardAnalytics`);
    }

    searchCompanyCustomers(searchObject) {
        return this.http.post(appConfig.apiUrl + `/customers/searchCompanyCustomers2`, {searchObject: searchObject});
    }

    getUserQuotes(pageNumber) {
        return this.http.get(appConfig.apiUrl + `/quotes/getUserQuotesWeb?pageNumber=${pageNumber}`);
    }

    searchUserCustomers(searchTerm) {
        return this.http.get(appConfig.apiUrl + `/customers/searchUserCustomers?searchTerm=${searchTerm}`);
    }

    getUserCustomers(pageNumber) {
        console.log(this.getAllCustomers);
        return this.http.get(appConfig.apiUrl + `/customers/getUserCustomers2?pageNumber=${pageNumber}`);
    }

    getUserDashboardAnalytics() {
        return this.http.get(appConfig.apiUrl + `/quotes/getUserDashboardAnalytics`);
    }

    setImage(companyId: any, image: any) {
        return this.http.post(appConfig.apiUrl + '/companies/setCompanyLogo', {companyId: companyId, imageObject: image});
    }

    setCompanyDocSettings(docSettingsObject: any) {
        return this.http.post(appConfig.apiUrl + '/companies/setCompanyDocSettings', {docSettingsObject: docSettingsObject});
    }

    getCompanyDocSettings() {
        return this.http.get(appConfig.apiUrl + '/companies/getCompanyDocSettings');
    }

    getCompany(companyId) {
        return this.http.get(appConfig.apiUrl + `/companies/${companyId}`);
    }

    setCompanySettings(companySettingsObject: any) {
        return this.http.post(appConfig.apiUrl + '/companies/setCompanySettings', {companySettingsObject: companySettingsObject});
    }
}