import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { appConfig } from '../app.config';
import { User } from '../models/user';

@Injectable()
export class ModalService {

    // Modal open binding variables
    newUserOpen = false;
    newProductOpen = false;
    newCustomizationOpen = false;
    addOptionsOpen = false;
    pricingEditorOpen = false;
    labelPickerOpen = false;
    mediaManagerOpen = false;
    editProductOpen = false;
    maxSizeEditorOpen = false;
    minSizeEditorOpen = false;
    newLabelOpen = false;
    newLabelOptionOpen = false;
    newQuoteOptionOpen = false;
    newProductCategoryOpen = false;
    editCompanyInfoOpen = false;
    newCompanyOpen = false;
    integrationMenuOpen = false;
    editUserPermissionsOpen = false;
    assignUsersToContactOpen = false;
    newProductCategoryOpen2 = false;
    newTeamOpen = false;

    // Modal objects
    currentPricingObject: any = null;
    currentSelectObject: any = null;
    currentSelectOptionObject: any = null;
    currentAddOptionsObject: any = null;
    currentOption: any = null;
    currentCategory: any = null;
    currentLabel: any = null;
    currentCustomerObject: any = null;

    // Other modal settings
    pricingType: any = null;
    optionEditing: any = false;
    mediaManagerMode: any = false;

    // Navbar
    showNavbar: any = false;

    constructor(private http: HttpClient) { }

    closeNewProduct() {
        this.newProductOpen = false;
    }

    openNewCustomization(object) {
        this.currentOption = object;
        if (this.currentOption) {
            this.optionEditing = true;
        }
        this.newCustomizationOpen = true;
        console.log(object)
    }

    closeNewCustomization() {
        this.newCustomizationOpen = false;
    }

    openAddOptions(object: any) {
        console.log(object);
        this.currentAddOptionsObject = object;
        this.addOptionsOpen = true;
    }

    closeAddOptions() {
        this.addOptionsOpen = false;
    }

    closePricingEditor() {
        this.pricingEditorOpen = false;
    }

    openPricingEditor(pricingObject: any, type) {
        this.currentPricingObject = pricingObject;
        this.pricingEditorOpen = true;
        this.pricingType = type;
        console.log(this.currentPricingObject);
    }

    closeLabelPicker() {
        this.labelPickerOpen = false;
    }

    openLabelPicker() {
        this.labelPickerOpen = true;
    }

    closeMediaManager() {
        this.mediaManagerOpen = false;
    }

    openMediaManager(mode) {
        this.mediaManagerMode = mode;
        this.mediaManagerOpen = true;
    }

    closeEditProduct() {
        this.editProductOpen = false;
    }

    openEditProduct() {
        this.editProductOpen = true;
    }

    openMaxSizeEditor() {
        this.maxSizeEditorOpen = true;
    }

    closeMaxSizeEditor() {
        this.maxSizeEditorOpen = false;
    }

    openMinSizeEditor() {
        this.minSizeEditorOpen = true;
    }

    closeMinSizeEditor() {
        this.minSizeEditorOpen = false;
    }

    openNewLabel(label) {
        this.currentLabel = label;
        this.newLabelOpen = true;
    }

    closeNewLabel() {
        this.newLabelOpen = false;
    }

    openNewLabelOption(selectObject: any, selectOptionObject: any) {
        console.log(selectObject);
        this.currentSelectObject = selectObject;
        this.currentSelectOptionObject = selectOptionObject;
        this.newLabelOptionOpen = true;
    }

    closeNewLabelOption() {
        this.newLabelOptionOpen = false;
    }

    openNewQuoteOption() {
        this.newQuoteOptionOpen = true;
    }

    closeNewQuoteOption() {
        this.newQuoteOptionOpen = false;
    }

    openNewProductCategory(id) {
        this.currentCategory = id;
        this.newProductCategoryOpen2 = true;
    }

    closeNewProductCategory() {
        this.newProductCategoryOpen2 = false;
    }

    openNewUser() {
        this.newUserOpen = true;
    }

    closeNewUser() {
        this.newUserOpen = false;
    }

    openEditCompanyInfo() {
        this.editCompanyInfoOpen = true;
    }

    closeEditCompanyInfo() {
        this.editCompanyInfoOpen = false;
    }

    openNewCompany() {
        this.newCompanyOpen = true;
    }

    closeNewCompany() {
        this.newCompanyOpen = false;
    }

    openIntegrationMenu() {
        this.integrationMenuOpen = true;
    }

    closeIntegrationMenu() {
        this.integrationMenuOpen = false;
    }

    openEditUserPermissionsMenu() {
        this.editUserPermissionsOpen = true;
    }

    closeEditUserPermissionsMenu() {
        this.editUserPermissionsOpen = false;
    }

    openAssignUsersToContact(currentCustomer) {
        console.log('------open');
        this.currentCustomerObject = currentCustomer;
        this.assignUsersToContactOpen = true;
    }

    closeAssignUsersToContact() {
        this.assignUsersToContactOpen = false;
    }

    openNewTeam() {
       this.newTeamOpen = true;
    }

    closeNewTeam() {
        this.newTeamOpen = false;
    }
}