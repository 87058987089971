import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { ProductService } from 'src/app/services/product.service';
import { ModalService } from 'src/app/services/modal.service';


@Component({
    templateUrl: 'new-label-option.component.html',
    selector: 'app-new-label-option',
})

export class NewLabelOptionComponent {
    base64textString: any;
    model: any = {
        image: this.base64textString
    };
    loading = false;
    currentUser: any;
    imageString: any;
    objectId: any;

    constructor(
        public router: Router,
        public userService: UserService,
        public alertService: AlertService,
        public productService: ProductService,
        public modalService: ModalService
        ) { 
            this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
            console.log(this.currentUser);
        }

    ngOnInit() {
        this.objectId = this.modalService.currentSelectObject._id;
        this.model = this.modalService.currentSelectOptionObject;
    }

    saveLabel() {
        this.loading = true;
        // if (this.productService.currentProduct) {
        //     this.model.id = this.productService.currentProduct;
        // }
        this.productService.saveLabelOption(this.modalService.currentSelectObject, this.model)
            .subscribe(
                data => {
                    this.alertService.success('label saved', true);
                    this.loading = false;
                    this.modalService.closeNewLabelOption();
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    handleFileSelect(evt: any){
        var files = evt.target.files;
        var file = files[0];
      
      if (files && file) {
          var reader = new FileReader();
  
          reader.onload =this._handleReaderLoaded.bind(this);
  
          reader.readAsBinaryString(file);
      }
    }
    
    _handleReaderLoaded(readerEvt: any) {
       var binaryString = readerEvt.target.result;
              this.base64textString = btoa(binaryString);
              this.base64textString = 'data:image/png;base64,' + this.base64textString;
              console.log(btoa(binaryString));
      }

    cancelNewProduct() {
        this.modalService.newProductOpen = false;
    }

    uploadImage() {
        this.productService.setImage(this.productService.currentProduct._id, this.base64textString).subscribe();
    }
}
