import { Component, OnInit } from '@angular/core';
import { CustomizationService } from 'src/app/services/customization.service';

import { User } from '../../models/user';
import { Route, ActivatedRoute, Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProductService } from 'src/app/services/product.service';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';

@Component({
    templateUrl: 'product-view.component.html',
    styleUrls: ['./product-view.component.scss']
})

export class ProductViewComponent implements OnInit {
    currentUser: User;
    products: any[] = [];
    productId: any;
    currentProduct: any;
    togglingActiveState = false;

    constructor(
        private userService: UserService,
        public modalService: ModalService,
        public productService: ProductService,
        public route: ActivatedRoute,
        private message: NzMessageService,
        private notification: NzNotificationService,
        public router: Router
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.productId = this.route.snapshot.paramMap.get("id");
        console.log(this.productId);
        this.loadProduct(this.productId);
    }

    deleteUser(_id: string) {
        this.userService.delete(_id).subscribe(() => { this.loadAllProducts() });
    }

    private loadAllProducts() {
        this.productService.getAll(this.currentUser).subscribe(products => { this.products = products; console.log(this.products); });

    }

    editProduct() {
        this.modalService.openEditProduct();
    }

    loadProduct(productId: any) {
        this.productService.getById(productId).subscribe(value => {
            console.log(value);
            this.productService.currentProduct = value;
            this.currentProduct = value;
            console.log(this.productService.currentProduct);
        });
    }

    removeCustomization(customization: any) {
        console.log(this.productService.currentProduct);
        customization.removing = true;
        this.productService.removeCustomization(this.productService.currentProduct._id, customization).subscribe(value => {
            console.log(value);
            this.productService.currentProduct.customizations = this.productService.currentProduct.customizations.filter(function (value: any) {
                return value._id != customization._id
            });
            customization.removing = false;
            // this.createMessageOptionRemoved();
            console.log(this.productService.currentProduct);
        });
    }

    activateProduct() {
        this.productService.activateProduct(this.currentProduct._id).subscribe(value => {
            console.log(value);
            this.currentProduct.active = true;
            this.togglingActiveState = false;
            // this.createNotificationActive();
        });
    }

    deactivateProduct() {
        this.productService.deactivateProduct(this.currentProduct._id).subscribe(value => {
            console.log(value);
            this.currentProduct.active = false;
            this.togglingActiveState = false;
            // this.createNotificationInactive();
        });
    }

    openMediaManager() {
        this.modalService.openMediaManager('PRODUCT');
    }

    removeLabel(label: any) {
        label.removing = true;
        this.productService.removeLabel(this.productService.currentProduct._id, label).subscribe(value => {
            this.productService.currentProduct.labels = this.productService.currentProduct.labels.filter(function (object: any) {
                return object._id != label._id
            })
            label.removing = false;
            // this.createMessageLabelRemoved();
            console.log(value);
        });
    }

    openAddOptions() {
        this.modalService.openAddOptions(this.currentProduct);
    }

    openPricingEditor(object: any) {
        this.modalService.openPricingEditor(object, 'product');
    }

    toggleActiveState() {
        this.togglingActiveState = true;
        if (this.currentProduct.active) {
            this.activateProduct();
        } else {
            this.deactivateProduct();
        }
    }


    createMessageActive(): void {
        this.message.create('success', `Product is now activated`);
    }

    createMessageInactive(): void {
        this.message.create('success', `Product is now deactivated`);
    }

    createMessageOptionRemoved(): void {
        this.message.create('success', `Option removed`);
    }

    createMessageLabelRemoved(): void {
        this.message.create('success', `Label removed`);
    }

    createNotificationActive(): void {
        this.notification.create(
            'success',
            'Product is now activated',
            '',
            { nzDuration: 3000 }
        );
    }

    createNotificationInactive(): void {
        this.notification.create(
            'success',
            'Product is now deactivated',
            '',
            { nzDuration: 3000 }
        );
    }

    saveProductObject() {
        this.currentProduct.image = this.productService.currentProduct.image;

        console.log(this.productService.currentProduct.image);
        console.log(this.currentProduct.image);

        this.productService.saveProductObject(this.currentProduct).subscribe(value => {
            console.log(value);
        });
    }

    onBack() {
        this.router.navigate([`catalog/product-categories/${this.currentProduct.parent._id}`]);
    }

    duplicateProduct() {
        this.productService.duplicateProduct(this.currentProduct).subscribe(value => {
            console.log(value);
            // this.router.navigate([`catalog/product-categories/products/${value['_id']}`]);
            this.router.navigate([`catalog/product-categories/${this.currentProduct.parent._id}`]);
        });
    }
}