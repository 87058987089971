import { Component, OnInit, HostListener } from '@angular/core';

import { User } from '../../models/user';
import { ProductService } from '../../services/product.service';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { CompanyService } from 'src/app/services/company.service';

@Component({
    templateUrl: 'quotes.component.html',
    styleUrls: ['./quotes.component.scss']
})

export class QuotesComponent implements OnInit {
    currentUser: User;
    products: any = [];
    originalProducts: any = [];
    sortName: string | null = null;
    sortValue: string | null = null;
    tablePageSize: any = 10;
    tablePageIndex: any;
    searchAddress: string;
    listOfDisplayData: any;
    loading = false;
    showAllQuotes = false;
    totalQuoteCount = 0;
    pageNumber = 0;
    loadedAllObjects = false;

    searchTerm: any = '';
    fetchingMoreObjects = false;

    constructor(
        public userService: UserService,
        public modalService: ModalService,
        public productService: ProductService,
        public companyService: CompanyService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.loadAllQuotes();
    }

    deleteUser(_id: string) {
        // this.userService.delete(_id).subscribe(() => { this.loadAllProducts() });
    }

    loadAllQuotes() {
        console.log('--------loadAllQuotes');
        this.loading = true;
        this.pageNumber = 0;

        if (this.companyService.getAllCustomers) {
            this.companyService.getCompanyQuotes(this.pageNumber).subscribe(products => {
                this.originalProducts = products['quotes'];
                this.products = products['quotes'];
                this.totalQuoteCount = products['count'];
                this.loading = false;
                console.log(products);
            });
        } else {
            this.companyService.getUserQuotes(this.pageNumber).subscribe(products => {
                console.log(products);
                this.originalProducts = products['quotes'];
                this.products = products['quotes'];
                this.totalQuoteCount = products['count'];
                this.loading = false;
                console.log(this.products);
            });
        }


    }

    newProduct() {
        this.modalService.newProductOpen = true;
    }

    sort(sort: { key: string; value: string }): void {
        console.log(sort);

        if (sort.value == "ascend") {
            this.products.sort(this.compare);
        } else {
            this.products.sort(this.compareDesc);
        }

        console.log(this.products);

        this.listOfDisplayData = this.products.splice(0, this.tablePageSize);
    }

    compare2(a, b) {
        if (a.referenceName < b.referenceName) {
            return -1;
        }
        if (a.referenceName > b.referenceName) {
            return 1;
        }
        return 0;
    }

    compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const bandA = a.updatedAt.toUpperCase();
        const bandB = b.updatedAt.toUpperCase();

        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        return comparison;
    }

    compareDesc(a, b) {
        // Use toUpperCase() to ignore character casing
        const bandA = a.referenceName.toUpperCase();
        const bandB = b.referenceName.toUpperCase();

        let comparison = 0;
        if (bandA < bandB) {
            comparison = 1;
        } else if (bandA > bandB) {
            comparison = -1;
        }
        return comparison;
    }

    // currentPageDataChange(event) {
    //     console.log(event);
    //     if (this.companyService.getAllCustomers) {
    //         this.companyService.getCompanyQuotes().subscribe(products => {
    //             this.originalProducts = products;
    //             this.products = products;
    //             this.loading = false;
    //             console.log(this.products);
    //         }); 
    //     } else {
    //         this.companyService.getUserQuotes(event - 1).subscribe(products => {
    //             console.log(products);
    //             this.originalProducts = products['quotes'];
    //             this.products = products['quotes'];
    //             this.totalQuoteCount = products['count'];
    //             this.loading = false;
    //             console.log(this.products);
    //         }); 
    //     }
    //     // this.listOfDisplayData = event;
    // }

    // currentPageSizeChange(event) {
    //     this.tablePageSize = event;
    // }

    filterItems(searchTerm) {
        this.products = this.originalProducts.filter(item => {
            return item.referenceName.toString().toLowerCase().indexOf(this.searchTerm.toString().toLowerCase()) > -1;
        });
    }

    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        if (event.target.scrollHeight - (event.target.scrollTop + event.target.offsetHeight) < 300 && this.fetchingMoreObjects != true) {
            this.fetchingMoreObjects = true;
            this.pageNumber++;

            console.log(event);
            console.log('----------------------------------------Fetch more');

            if (this.companyService.getAllCustomers) {
                // this.companyService.getCompanyQuotes(this.pageNumber).subscribe(products => {
                //     this.originalProducts = products;
                //     this.products = products;
                //     this.loading = false;
                //     console.log(this.products);
                // });
                this.companyService.getCompanyQuotes(this.pageNumber).subscribe(products => {
                    console.log(products);
                    this.originalProducts = this.originalProducts.concat(products['quotes']);
                    if (products['quotes'].length == 0) {
                        this.loadedAllObjects = true;
                    }
                    this.fetchingMoreObjects = false;
                });
            } else {
                this.companyService.getUserQuotes(this.pageNumber).subscribe(products => {
                    console.log(products);
                    this.originalProducts = this.originalProducts.concat(products['quotes']);
                    if (products['quotes'].length == 0) {
                        this.loadedAllObjects = true;
                    }
                    this.fetchingMoreObjects = false;
                });
            }


        }
    }

}