import { Component, OnInit } from '@angular/core';

import { User } from '../../models/user';
import { ProductService } from '../../services/product.service';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import {
    trigger,
    state,
    style,
    animate,
    transition
  } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: 'product-variants.component.html',
    styleUrls: ['./product-variants.component.scss'],
    animations: [
        trigger('flyInDown', [
          state('in', style({ transform: 'translateY(0)', opacity: 1.0 })),
          transition('void => *', [
            style({ transform: 'translateY(0px)', opacity: 0.5 }),
            animate(250)
          ])
        ]),
        trigger('flyInUp', [
          state('in', style({ transform: 'translateY(0)', opacity: 1.0 })),
          transition('void => *', [
            style({ transform: 'translateY(0px)', opacity: 0.5 }),
            animate(250)
          ])
        ])
      ]
})

export class ProductVariantsComponent implements OnInit {
    currentUser: User;
    products: any = [];
    originalProducts: any = [];
    sortName: string | null = null;
    sortValue: string | null = null;
    tablePageSize: any = 10;
    tablePageIndex: any;
    searchAddress: string;
    listOfDisplayData: any;
    loading = true;
    categoryId: any;
    productCategoryObject: any;

    searchTerm: any = '';

    constructor(public router: Router, public route: ActivatedRoute, private userService: UserService, public modalService: ModalService, public productService: ProductService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        // this.loadAllProducts();
        this.categoryId = this.route.snapshot.paramMap.get("id");
        console.log(this.categoryId);
        this.productService.getProductsInCategory(this.categoryId).subscribe(value => {
            console.log(value);
            this.productCategoryObject = value;
            this.originalProducts = value['products'];
            this.products = value['products']; 
            this.loading = false;
        })
    }

    deleteUser(_id: string) {
        this.userService.delete(_id).subscribe(() => { this.loadAllProducts() });
    }

    private loadAllProducts() {
        this.loading = true;
        this.productService.getAll(this.currentUser).subscribe(products => { 
            this.originalProducts = products;
            this.products = products; 
            this.loading = false;
            console.log(this.products); 
        });

    }

    newProduct() {
        this.modalService.newProductOpen = true;
    }

    sort(sort: { key: string; value: string }): void {
        console.log(sort);

        if (sort.value == "ascend") {
            this.products.sort(this.compare);
        } else {
            this.products.sort(this.compareDesc);
        }

        console.log(this.products);

        this.listOfDisplayData = this.products.splice(0, this.tablePageSize);
    }

    compare2(a, b) {
        if (a.referenceName < b.referenceName) {
            return -1;
        }
        if (a.referenceName > b.referenceName) {
            return 1;
        }
        return 0;
    }

    compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const bandA = a.updatedAt.toUpperCase();
        const bandB = b.updatedAt.toUpperCase();

        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        return comparison;
    }

    compareDesc(a, b) {
        // Use toUpperCase() to ignore character casing
        const bandA = a.referenceName.toUpperCase();
        const bandB = b.referenceName.toUpperCase();

        let comparison = 0;
        if (bandA < bandB) {
            comparison = 1;
        } else if (bandA > bandB) {
            comparison = -1;
        }
        return comparison;
    }

    currentPageDataChange(event) {
        console.log(event);
        this.listOfDisplayData = event;
    }

    currentPageSizeChange(event) {
        this.tablePageSize = event;
    }

    filterItems(searchTerm) {
        this.products = this.originalProducts.filter(item => {
            return item.referenceName.toString().toLowerCase().indexOf(this.searchTerm.toString().toLowerCase()) > -1;
        });
    }

    onBack() {
        this.router.navigate([`catalog/product-categories`]);
    }

}