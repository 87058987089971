import { Component, OnInit } from '@angular/core';

import { User } from '../../models/user';
import { Route, ActivatedRoute, Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProductService } from 'src/app/services/product.service';
import { CustomizationService } from 'src/app/services/customization.service';

@Component({
    templateUrl: 'new-option-view.component.html',
    styleUrls: ['./new-option-view.component.scss']
})

export class NewOptionViewComponent implements OnInit {
    currentUser: User;
    products: any[] = [];
    optionId: any;
    currentOption: any;
    model = {};
    customizationTypes: any = [];
    priceTypes: any = [];
    tierThresholdUnits: any = [];

    newTierTemplate: any = {
        min: 1,
        price: 0,
        priceType: null
    }

    pageMode = null;

    switchType = {
        _id: "5de70f94e7179a0d18de7ce3",
        type: "SWITCH",
        displayName: "Switch"
    }

    heightWidthType = {
        _id: "5de70fb1e7179a0d18de7cec",
        type: "MEASURE_HEIGHT_WIDTH",
        displayName: "Measure height & width"
    }

    numberFieldType = {
        _id: "5de70fa9e7179a0d18de7ceb",
        type: "COUNT",
        displayName: "Count"
    }

    textFieldType = {
        _id: "5de70fbae7179a0d18de7ced",
        type: "SWITCH_TEXT",
        displayName: "Switch with text input"
    }

    constructor(
        private userService: UserService,
        public modalService: ModalService,
        public productService: ProductService,
        public customizationService: CustomizationService,
        public route: ActivatedRoute,
        public router: Router
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {

        console.log(this.optionId);
        this.getCustomizationTypes();
        this.getPriceTypes();

        console.log(this.router.url);

        if (this.router.url.slice(-4) == 'edit') {
            this.pageMode = 'EDIT';
            this.optionId = this.route.snapshot.paramMap.get("id");
            this.loadOption(this.optionId);

        } else {
            this.pageMode = 'NEW'

            this.customizationService.currentOption = {
                active: false,
                displayName: '',
                price: {
                    type: 'STANDARD'
                },
                type: {
                    _id: "5de70f94e7179a0d18de7ce3",
                    type: "SWITCH",
                    displayName: "Switch"
                }
            };
        }

        console.log(this.pageMode);
    }

    deleteUser(_id: string) {
        this.userService.delete(_id).subscribe(() => { this.loadAllProducts() });
    }

    private loadAllProducts() {
        this.productService.getAll(this.currentUser).subscribe(products => { this.products = products; console.log(this.products); });
    }

    editCustomization() {
        this.modalService.openNewCustomization(this.currentOption);
    }

    loadOption(optionId: any) {
        this.customizationService.getById(optionId).subscribe(value => {
            if (value['active'] == null) {
                value['active'] = true;
            }

            this.customizationService.currentOption = value;
            this.currentOption = value;

            console.log(this.currentOption);
        });
    }

    removeCustomization(customization: any) {
        this.productService.removeCustomization(this.productService.currentProduct._id, customization).subscribe(value => {
            console.log(value);
        });
    }

    // activateProduct() {
    //     this.productService.activateProduct(this.currentProduct._id).subscribe(value => {
    //         console.log(value);
    //     });
    // }

    // deactivateProduct() {
    //     this.productService.deactivateProduct(this.currentProduct._id).subscribe(value => {
    //         console.log(value);
    //     });
    // }

    removeLabel(label: any) {
        this.productService.removeLabel(this.productService.currentProduct._id, label).subscribe(value => {
            console.log(value);
        });
    }

    openPricingEditor(object: any) {
        this.modalService.openPricingEditor(object, 'option');
    }

    saveCustomizationObject() {
        if (this.customizationService.currentOption.price.type == "TIERED") {
            for (let [index, tier] of this.customizationService.currentOption.price.tiers.entries()) {
                if (tier['priceType'].priceType == 'PERCENTAGE') {
                    tier['price'] == tier['price'] / 100;
                }

                if (index != 0) {
                    tier.min = this.customizationService.currentOption.price.tiers[index - 1].max;
                }

                tier.sortPosition = index;
            }
        }

        this.customizationService.createCustomizationObject(this.customizationService.currentOption).subscribe(value => {
            console.log(value);
            this.router.navigate(['/catalog/options', value['_id']]);
        });
    }

    private getCustomizationTypes() {
        this.customizationService.getCustomizationTypes().subscribe(dataObject => {
            this.customizationTypes = dataObject;
            console.log(dataObject);
        });
    }

    getPriceTypes() {
        this.productService.getPriceTypes().subscribe(dataObject => {
            this.priceTypes = dataObject['priceTypes'];
            this.tierThresholdUnits = dataObject['tierThresholdUnits'];
            console.log(dataObject);
        });
    }

    changeToTiered() {

        this.customizationService.currentOption.price = {
            tierThresholdUnit: this.tierThresholdUnits[0],
            type: "TIERED",
            tiers: [
                {
                    max: 1,
                    price: 0,
                    priceType: null
                },
                {
                    min: 1,
                    price: 0,
                    priceType: null
                }
            ]
        }
    }

    changeToStandard() {

        this.customizationService.currentOption.price = {
            type: 'STANDARD'
        }
    }


    addTier() {
        this.customizationService.currentOption.price.tiers.push(JSON.parse(JSON.stringify(this.newTierTemplate)));
        this.customizationService.currentOption.price.tiers[this.customizationService.currentOption.price.tiers.length - 2].max = this.productService.currentProduct.price.tiers[this.productService.currentProduct.price.tiers.length - 3].max;
        console.log(this.customizationService.currentOption.price.tiers);
    }

    removeLastTier() {
        this.customizationService.currentOption.price.tiers.pop();
        this.customizationService.currentOption.price.tiers[this.productService.currentProduct.price.tiers.length - 1].max = null;
    }

    compareFn = (o1: any, o2: any) => (o1 && o2 ? o1._id === o2._id : o1 === o2);

    logType() {
        console.log(this.customizationService.currentOption.type);
    }

}