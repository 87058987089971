import { Component, OnInit } from '@angular/core';

import { User } from '../../models/user';
import { Route, ActivatedRoute } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
    templateUrl: 'label-view.component.html',
    styleUrls: ['./label-view.component.scss']
})

export class LabelViewComponent implements OnInit {
    currentUser: User;
    products: any[] = [];
    productId: any;
    labelId: any;
    currentProduct: any;
    currentLabel: any;

    constructor(
        private userService: UserService,
        public modalService: ModalService,
        public productService: ProductService,
        public route: ActivatedRoute
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.labelId = this.route.snapshot.paramMap.get("id");
        this.loadLabel(this.labelId);
    }

    deleteUser(_id: string) {
        this.userService.delete(_id).subscribe(() => { this.loadAllProducts() });
    }

    private loadAllProducts() {
        this.productService.getAll(this.currentUser).subscribe(products => { this.products = products; console.log(this.products); });

    }

    editProduct() {
        this.modalService.openEditProduct();
    }

    loadLabel(labelId: any) {
        this.productService.getOneLabel(labelId).subscribe(value => {
            console.log(value);
            this.currentLabel = value;
            this.productService.currentLabel = value;
        });
    }

    removeCustomization(customization: any) {
        this.productService.removeCustomization(this.productService.currentProduct._id, customization).subscribe(value => {
            console.log(value);
        });
    }

    activateProduct() {
        this.productService.activateProduct(this.currentProduct._id).subscribe(value => {
            console.log(value);
        });
    }

    deactivateProduct() {
        this.productService.deactivateProduct(this.currentProduct._id).subscribe(value => {
            console.log(value);
        });
    }
}