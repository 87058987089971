import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { ProductService } from 'src/app/services/product.service';
import { ModalService } from 'src/app/services/modal.service';
import { CustomizationService } from 'src/app/services/customization.service';
import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
    templateUrl: 'modal-assign-users-to-contact.component.html',
    selector: 'app-modal-assign-users-to-contact',
})

export class ModalAssignUsersToContactComponent {
    base64textString: any;
    model: any = {
        image: this.base64textString
    };
    loading = false;
    currentUser: any;
    customizations: any;
    users: any;

    customizationsToAdd: any = [];
    usersToAdd: any = [];

    objectId: any;
    currentObject: any;

    constructor(
        public router: Router,
        public userService: UserService,
        public alertService: AlertService,
        public productService: ProductService,
        public modalService: ModalService,
        public customizationService: CustomizationService,
        private message: NzMessageService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log(this.currentUser);
    }

    ngOnInit() {
        this.loadCompanyUsers();
        // this.objectId = this.modalService.currentAddOptionsObject._id;
        // this.currentObject = this.modalService.currentAddOptionsObject;
    }

    save() {
        this.loading = true;
        // this.customizationService.create(this.model)
        //     .subscribe(
        //         data => {
        //             this.alertService.success('Registration successful', true);
        //             this.loading = false;
        //         },
        //         error => {
        //             this.alertService.error(error);
        //             this.loading = false;
        //         });
        this.userService.assignUsersToContact(this.usersToAdd, this.modalService.currentCustomerObject._id)
            .subscribe(
                data => {
                    this.loading = false;
                    this.modalService.closeAssignUsersToContact();
                },
                error => {
                    this.loading = false;
                    this.modalService.closeAssignUsersToContact();
                });
    }

    handleFileSelect(evt: any) {
        var files = evt.target.files;
        var file = files[0];

        if (files && file) {
            var reader = new FileReader();

            reader.onload = this._handleReaderLoaded.bind(this);

            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoaded(readerEvt: any) {
        var binaryString = readerEvt.target.result;
        this.base64textString = btoa(binaryString);
        console.log(btoa(binaryString));
    }

    private loadAllCustomizations() {
        this.customizationService.getAll(this.currentUser).subscribe(customizations => {
            this.customizations = customizations;
            console.log(this.customizations);
        });
    }

    loadCompanyUsers() {
        this.userService.getAll(this.currentUser).subscribe(users => { 
            this.users = users; 
        });
    }

    checkIfAdded(customization: any) {
        let value = false;
        for (let currentCust of this.currentObject.customizations) {
            console.log(currentCust);
            if (currentCust['_id'] == customization._id) {
                return true;
            } else {
                value = false;
            }
        }
        return value;
    }

    toggleCustomization(customization: any) {
        console.log(customization);
        if (customization.isChecked) {
            for (let index in this.customizationsToAdd) {
                let currentCust = this.customizationsToAdd[index];
                if (currentCust._id == customization._id) {
                    this.customizationsToAdd.splice(index, 1);
                    console.log(this.customizationsToAdd);
                    customization.isChecked = false;
                }
            }
        } else {
            customization.isChecked = true;
            this.customizationsToAdd.push(customization);
            console.log(this.customizationsToAdd);
        }
    }

    addCustomizations() {
        this.loading = true;
        this.productService.addCustomizations(this.objectId, this.customizationsToAdd).subscribe(value => {
            console.log(value);
            this.modalService.currentAddOptionsObject.customizations = this.modalService.currentAddOptionsObject.customizations.concat(this.customizationsToAdd);
            this.loading = false;
            this.modalService.closeAddOptions();
            this.createMessage();
        })
    }

    createMessage(): void {
        this.message.create('success', `Options added successfully`);
    }
}
