import { Component, OnInit } from '@angular/core';

import { User } from '../../models/user';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
    templateUrl: 'users.component.html'
})

export class UsersComponent implements OnInit {
    currentUser: User;
    users: any[] = [];
    tablePageSize: any = 10;
    tablePageIndex: any;
    listOfDisplayData: any;
    searchTerm: any;
    originalUsers: any[] = [];
    loading = true;

    constructor(
        private userService: UserService,
        public modalService: ModalService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.loadAllUsers();
    }

    deleteUser(_id: string) {
        this.userService.delete(_id).subscribe(() => { this.loadAllUsers() });
    }

    loadAllUsers() {
        this.userService.loading = true;
        this.userService.getAll(this.currentUser).subscribe(users => { 
            this.originalUsers = users;
            this.users = users; 
            this.userService.users = users;
            this.userService.loading = false;
            this.loading = false;
        });
    }

    newUser() {
        this.modalService.newUserOpen = true;
    }

    currentPageDataChange(event) {
        console.log(event);
        this.listOfDisplayData = event;
    }

    currentPageSizeChange(event) {
        this.tablePageSize = event;
    }

    filterItems(searchTerm) {
        this.users = this.originalUsers.filter(item => {
            return item.email.toString().toLowerCase().indexOf(this.searchTerm.toString().toLowerCase()) > -1;
        });
    }


}