import { Component, OnInit } from '@angular/core';

import { User } from '../../models/user';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProductService } from 'src/app/services/product.service';
import { CustomizationService } from 'src/app/services/customization.service';

@Component({
    templateUrl: 'customizations.component.html'
})

export class CustomizationsComponent implements OnInit {
    currentUser: User;
    customizations: any[] = [];
    originalCustomizations: any[] = [];
    listOfDisplayData: any;
    searchTerm: any = '';
    loading = true;

    constructor(private userService: UserService,
        public modalService: ModalService,
        public productService: ProductService,
        public customizationService: CustomizationService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.loadAllCustomizations();
        this.customizationService.currentOption = null;
    }

    loadAllCustomizations() {
        this.loading = true;
        this.customizationService.getAll(this.currentUser).subscribe(customizations => { 
            this.originalCustomizations = customizations;
            this.customizations = customizations; 
            console.log(this.customizations); 
            this.loading = false;
        });
    }

    new() {
        this.modalService.openNewCustomization(null);
    }


    currentPageDataChange(event) {
        console.log(event);
        this.listOfDisplayData = event;
    }

    filterItems(searchTerm) {
        this.customizations = this.originalCustomizations.filter(item => {
            return item.referenceName.toString().toLowerCase().indexOf(this.searchTerm.toString().toLowerCase()) > -1;
        });
    }
}