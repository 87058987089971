import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgZorroAntdModule, NzConfig, NZ_CONFIG, NZ_I18N, en_US } from 'ng-zorro-antd';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TestComponentComponent } from './test-component/test-component.component';
import { HomeComponent } from './components/home/home.component';
import { UserService } from './services/user.service';
import { HttpClientModule } from '@angular/common/http';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AuthenticationService } from './services/authentication.service';
import { AlertService } from './services/alert.service';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { ErrorInterceptorProvider } from './helpers/error.interceptor';
import { JwtInterceptorProvider } from './helpers/jwt.interceptor';
import { ProductService } from './services/product.service';
import { CustomizationService } from './services/customization.service';
import { ProductsComponent } from './components/products/products.component';
import { ModalService } from './services/modal.service';
import { ProductViewComponent } from './components/product-view/product-view.component';
import { PricingEditorComponent } from './components/pricing-editor/pricing-editor.component';
import { LabelPickerComponent } from './components/label-picker/label-picker.component';
import { EditProductComponent } from './components/edit-product/edit-product.component';
import { ResetPasswordComponent } from './components/resetPassword/reset-password.component';
import { UsersComponent } from './components/users/users.component';
import { NewUserComponent } from './components/newUser/new-user.component';
import { NewProductComponent } from './components/new-product/new-product.component';
import { CustomizationsComponent } from './components/customizations/customizations.component';
import { NewCustomizationComponent } from './components/newCustomization/new-customization.component';
import { AddOptionsComponent } from './components/add-options/add-options.component';
import { MediaManagerComponent } from './components/media-manager/media-manager.component';
import { MaxSizeEditorComponent } from './components/max-size-editor/max-size-editor.component';
import { MinSizeEditorComponent } from './components/min-size-editor/min-size-editor.component';
import { LabelsComponent } from './components/labels/labels.component';
import { NewLabelComponent } from './components/new-label/new-label.component';
import { LabelViewComponent } from './components/label-view/label-view.component';
import { NewLabelOptionComponent } from './components/new-label-option/new-label-option.component';
import { OptionViewComponent } from './components/option-view/option-view.component';
import { QuoteOptionsComponent } from './components/quote-options/quote-options.component';
import { NewQuoteOptionComponent } from './components/new-quote-option/new-quote-option.component';
import { QuoteOptionViewComponent } from './components/quote-option-view/quote-option-view.component';
import { NewProductCategoryComponent } from './components/new-product-category/new-product-category.component';
import { ProductCategoriesComponent } from './components/product-categories/product-categories.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminHomeComponent } from './components/admin-home/admin-home.component';
import { AdminSidebarComponent } from './components/admin-sidebar/admin-sidebar.component';
import { AdminCompaniesComponent } from './components/admin-companies/admin-companies.component';
import { CompanyService } from './services/company.service';
import { SettingsComponent } from './components/settings/settings.component';
import { EditCompanyInfoComponent } from './components/edit-company-info/edit-company-info.component';
import { QuotesComponent } from './components/quotes/quotes.component';
import { CustomerComponent } from './components/customers/customers.component';
import { LocationsComponent } from './components/locations/locations.component';
import { AdminNewCompanyComponent } from './components/admin-new-company/admin-new-company.component';
import { IntegrationMenuComponent } from './components/integration-menu/integration-menu.component';
import { IntegrationService } from './services/integration.service';
import { KeapCallbackComponent } from './components/keap-callback/keap-callback.component';
import { UserViewComponent } from './components/user-view/user-view.component';
import { EditUserPermissionComponent } from './components/edit-user-permissions/edit-user-permissions.component';
import { CustomerViewComponent } from './components/customer-view/customer-view.component';
import { QuoteViewComponent } from './components/quote-view/quote-view.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ModalAssignUsersToContactComponent } from './components/modal-assign-users-to-contact/modal-assign-users-to-contact.component';
import { SettingsCompanyInfoComponent } from './components/settings-company-info/settings-company-info.component';
import { SettingsIntegrationsComponent } from './components/settings-integrations/settings-integrations.component';
import { CatalogComponent } from './components/catalog/catalog.component';
import { AfterAccountSetupComponent } from './components/after-account-setup/after-account-setup.component';
import { AccountSetupComponent } from './components/account-setup/account-setup.component';
import { SettingsPdfComponent } from './components/settings-pdf/settings-pdf.component';
import { SignUpUserInfoComponent } from './components/sign-up-user-info/sign-up-user-info.component';
import { SignUpCompanyInfoComponent } from './components/sign-up-company-info/sign-up-company-info.component';
import { GettingStartedComponent } from './components/getting-started/getting-started.component';
import { NewProductViewComponent } from './components/new-product-view/new-product-view.component';
import { EditProductView } from './components/edit-product-view/edit-product-view.component';
import { NewOptionViewComponent } from './components/new-option-view/new-option-view.component';
import { ProductVariantsComponent } from './components/product-variants/product-variants.component';
import { NewTeamComponent } from './components/new-team/new-team.component';
import { NewQuoteView } from './components/new-quote-view/new-quote-view.component';
import { NewQuoteItemViewComponent } from './components/new-quote-item-view/new-quote-item-view.component';

@NgModule({
  declarations: [
    AppComponent,
    TestComponentComponent,
    HomeComponent,
    SidebarComponent,
    LoginComponent,
    ProductsComponent,
    ProductViewComponent,
    PricingEditorComponent,
    LabelPickerComponent,
    EditProductComponent,
    ResetPasswordComponent,
    UsersComponent,
    NewUserComponent,
    NewProductComponent,
    CustomizationsComponent,
    NewCustomizationComponent,
    AddOptionsComponent,
    MediaManagerComponent,
    MaxSizeEditorComponent,
    MinSizeEditorComponent,
    LabelsComponent,
    NewLabelComponent,
    LabelViewComponent,
    NewLabelOptionComponent,
    OptionViewComponent,
    QuoteOptionsComponent,
    NewQuoteOptionComponent,
    QuoteOptionViewComponent,
    NewProductCategoryComponent,
    ProductCategoriesComponent,
    AdminHomeComponent,
    AdminSidebarComponent,
    AdminCompaniesComponent,
    SettingsComponent,
    EditCompanyInfoComponent,
    QuotesComponent,
    CustomerComponent,
    LocationsComponent,
    AdminCompaniesComponent,
    AdminNewCompanyComponent,
    IntegrationMenuComponent,
    KeapCallbackComponent,
    UserViewComponent,
    EditUserPermissionComponent,
    CustomerViewComponent,
    QuoteViewComponent,
    DashboardComponent,
    ModalAssignUsersToContactComponent,
    SettingsCompanyInfoComponent,
    SettingsIntegrationsComponent,
    CatalogComponent,
    AfterAccountSetupComponent,
    AccountSetupComponent,
    SettingsPdfComponent,
    SignUpUserInfoComponent,
    SignUpCompanyInfoComponent,
    GettingStartedComponent,
    NewProductViewComponent,
    EditProductView,
    NewOptionViewComponent,
    ProductVariantsComponent,
    NewTeamComponent,
    NewQuoteView,
    NewQuoteItemViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    NgZorroAntdModule
  ],
  providers: [
    UserService,
    AuthenticationService,
    AlertService,
    AuthGuard,
    JwtInterceptorProvider,
    ProductService,
    CustomizationService,
    ModalService,
    CompanyService,
    { provide: NZ_I18N, useValue: en_US },
    IntegrationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
