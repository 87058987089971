import { Component, OnInit } from '@angular/core';

import { User } from '../../models/user';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IntegrationService } from 'src/app/services/integration.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
    templateUrl: 'keap-callback.component.html'
})

export class KeapCallbackComponent implements OnInit {
    currentUser: User;
    users: any[] = [];
    tablePageSize: any = 10;
    tablePageIndex: any;
    listOfDisplayData: any;
    searchTerm: any;
    originalUsers: any[] = [];

    constructor(
        private userService: UserService,
        public modalService: ModalService,
        public route: ActivatedRoute,
        public integrationService: IntegrationService,
        private router: Router,
        private notification: NzNotificationService

    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        let state = this.route.snapshot.queryParamMap.get('state');
        let code = this.route.snapshot.queryParamMap.get('code');
        console.log(state);
        console.log(code);
        this.integrationService.keapAuthCallback(state, code).subscribe(value => {
            console.log(value);
            this.createNotificationCreated();
            this.router.navigateByUrl('/settings');
        });
    }

    createNotificationCreated(): void {
        this.notification.create(
            'success',
            'Keap account connected successfully',
            '',
            { nzDuration: 3000 }
        );
    }
}