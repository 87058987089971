import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { appConfig } from '../app.config';
import { User } from '../models/user';

@Injectable()
export class UserService {
    currentUser = JSON.parse(localStorage.getItem('currentUser'));
    currentViewUser: any;

    users: any;
    loading = false;
    teams: any = [];

    constructor(private http: HttpClient) { }

    getAll(currentUser: any) {
        return this.http.get<User[]>(appConfig.apiUrl + '/users?user=' + currentUser._id);
    }

    getById(_id: string) {
        return this.http.get(appConfig.apiUrl + '/users/' + _id);
    }

    create(newUser, creator: User) {
        return this.http.post(appConfig.apiUrl + '/users/register', {newUser: newUser, creator: creator});
    }

    update(user: User) {
        return this.http.put(appConfig.apiUrl + '/users/' + user._id, user);
    }

    delete(_id: string) {
        return this.http.delete(appConfig.apiUrl + '/users/' + _id);
    }

    changePassword(data: any, token: any) {
        return this.http.post(appConfig.apiUrl + '/users/changePassword', {'newPassword': data.newPassword, 'token': token});
    }

    getCurrentUser() {
        return this.http.get(appConfig.apiUrl + `/users/getUser/${this.currentUser._id}/${this.currentUser.company._id}`);
    }

    getUser(userId) {
        return this.http.get(appConfig.apiUrl + `/users/getUser/${userId}/${this.currentUser.company._id}`);
    }

    checkPermission(permissionName) {
        if (this.currentUser['userObject'] && this.currentUser['userObject'].permissions) {
            for (let permission of this.currentUser['userObject'].permissions) {
                if (permission.name == permissionName) {
                    return true
                }
            }
    
            return false;
        } else {
            return false;
        }
    }

    saveUserPermissions(permissionsObject, userId, companyId) {
        return this.http.post(appConfig.apiUrl + `/users/saveUserPermissions/`, {permissionsObject: permissionsObject, userId: userId, companyId: companyId});
    }

    assignUsersToContact(usersArray, customerId) {
        return this.http.post(appConfig.apiUrl + `/customers/assignUsersToContact/`, {usersArray: usersArray, customerId: customerId});
    }

    loadAllUsers() {
        this.loading = true;
        this.getAll(this.currentUser).subscribe(users => { 
            this.users = users; 
            this.loading = false;
        });
    }

    updateUser(user) {
        return this.http.post(appConfig.apiUrl + '/users/updateUser', {user});
    }

    saveTeam(teamObject) {
        return this.http.post(appConfig.apiUrl + `/users/saveTeam/`, {teamObject: teamObject});
    }

    getCompanyTeams() {
        return this.http.get(appConfig.apiUrl + `/users/getCompanyTeams/`);
    }

    getCompanyTeamsLocal() {
        this.getCompanyTeams().subscribe(value => {
            console.log(value);
            this.teams = value;
        })
    }

    getQuotePdf(quoteId) {
        return this.http.get(appConfig.apiUrl + `/quotes/getQuotePdf/${quoteId}`);
    }

}