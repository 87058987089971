import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductViewComponent } from './components/product-view/product-view.component';
import { ResetPasswordComponent } from './components/resetPassword/reset-password.component';
import { UsersComponent } from './components/users/users.component';
import { CustomizationsComponent } from './components/customizations/customizations.component';
import { OptionViewComponent } from './components/option-view/option-view.component';
import { LabelsComponent } from './components/labels/labels.component';
import { LabelViewComponent } from './components/label-view/label-view.component';
import { QuoteOptionsComponent } from './components/quote-options/quote-options.component';
import { QuoteOptionViewComponent } from './components/quote-option-view/quote-option-view.component';
import { ProductCategoriesComponent } from './components/product-categories/product-categories.component';
import { AdminHomeComponent } from './components/admin-home/admin-home.component';
import { AdminCompaniesComponent } from './components/admin-companies/admin-companies.component';
import { SettingsComponent } from './components/settings/settings.component';
import { QuotesComponent } from './components/quotes/quotes.component';
import { CustomerComponent } from './components/customers/customers.component';
import { LocationsComponent } from './components/locations/locations.component';
import { KeapCallbackComponent } from './components/keap-callback/keap-callback.component';
import { UserViewComponent } from './components/user-view/user-view.component';
import { CustomerViewComponent } from './components/customer-view/customer-view.component';
import { QuoteViewComponent } from './components/quote-view/quote-view.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SettingsCompanyInfoComponent } from './components/settings-company-info/settings-company-info.component';
import { SettingsIntegrationsComponent } from './components/settings-integrations/settings-integrations.component';
import { CatalogComponent } from './components/catalog/catalog.component';
import { AfterAccountSetupComponent } from './components/after-account-setup/after-account-setup.component';
import { AccountSetupComponent } from './components/account-setup/account-setup.component';
import { SettingsPdfComponent } from './components/settings-pdf/settings-pdf.component';
import { SignUpUserInfoComponent } from './components/sign-up-user-info/sign-up-user-info.component';
import { SignUpCompanyInfoComponent } from './components/sign-up-company-info/sign-up-company-info.component';
import { GettingStartedComponent } from './components/getting-started/getting-started.component';
import { NewProductViewComponent } from './components/new-product-view/new-product-view.component';
import { EditProductView } from './components/edit-product-view/edit-product-view.component';
import { NewOptionViewComponent } from './components/new-option-view/new-option-view.component';
import { ProductVariantsComponent } from './components/product-variants/product-variants.component';
import { NewQuoteView } from './components/new-quote-view/new-quote-view.component';
import { NewQuoteItemViewComponent } from './components/new-quote-item-view/new-quote-item-view.component';


const appRoutes: Routes = [
  { path: 'integrations/keap/callback/auth', component: KeapCallbackComponent },
  { path: 'reset/:token', component: ResetPasswordComponent },
  { path: 'account-setup/:token', component: AccountSetupComponent },
  { path: 'download-apps', component: AfterAccountSetupComponent},
  { path: 'login', component: LoginComponent },
  { path: 'signup/1', component: SignUpUserInfoComponent },
  { path: 'signup/2', component: SignUpCompanyInfoComponent },
  {
    path: 'admin', component: AdminHomeComponent, canActivate: [AuthGuard], children: [
      { path: 'companies', component: AdminCompaniesComponent },
    ]
  },
  {
    path: '', component: HomeComponent, canActivate: [AuthGuard], children: [
      // { path: 'home', component: DashboardComponent},
      { path: 'users', component: UsersComponent, data: {permission: 'admin.view'} },
      { path: 'catalog/options/new', component: NewOptionViewComponent },
      { path: 'options', component: CustomizationsComponent, canActivate: [AuthGuard], data: {permission: 'catalog.view'} },
      { path: 'options/:id', component: OptionViewComponent, data: {permission: 'catalog.view'} },
      { path: 'catalog/options/:id/edit', component: NewOptionViewComponent },
      { path: 'labels', component: LabelsComponent, data: {permission: 'catalog.view'} },
      { path: 'label/:id', component: LabelViewComponent, data: {permission: 'catalog.view'} },
      { path: 'quote-options', component: QuoteOptionsComponent, data: {permission: 'catalog.view'} },
      { path: 'quote-option/:id', component: QuoteOptionViewComponent, data: {permission: 'catalog.view'} },
      { path: 'product-categories', component: ProductCategoriesComponent, data: {permission: 'catalog.view'} },
      { path: 'products', component: ProductCategoriesComponent, canActivate: [AuthGuard] },
      { path: 'products/group/:id', component: ProductViewComponent, canActivate: [AuthGuard] },
      { path: 'products/new', component: NewProductViewComponent, canActivate: [AuthGuard] },
      { path: 'products/:id/edit', component: EditProductView, canActivate: [AuthGuard] },

      // { path: 'settings', component: SettingsComponent, data: {permission: 'admin.view'} },
      {
        path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], children: [
          { path: '', redirectTo: 'company-info', pathMatch: 'full' },
          { path: 'company-info', component: SettingsCompanyInfoComponent, canActivate: [AuthGuard] },
          { path: 'pdf', component: SettingsPdfComponent, canActivate: [AuthGuard] },
          { path: 'integrations', component: SettingsIntegrationsComponent, canActivate: [AuthGuard] },
          { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
          { path: 'users/:id', component: UserViewComponent, data: {permission: 'admin.view'} },
          { path: 'sales', component: QuoteOptionsComponent, canActivate: [AuthGuard] },
        ]
      },
      {
        path: 'catalog', component: CatalogComponent, canActivate: [AuthGuard], children: [
          { path: '', redirectTo: 'product-categories', pathMatch: 'full' },
          { path: 'products', component: ProductsComponent, canActivate: [AuthGuard] },
          { path: 'options', component: CustomizationsComponent, canActivate: [AuthGuard] },
          { path: 'options/:id', component: OptionViewComponent, data: {permission: 'catalog.view'} },
          { path: 'quote-options', component: QuoteOptionsComponent, canActivate: [AuthGuard] },
          { path: 'labels', component: LabelsComponent, canActivate: [AuthGuard] },
          { path: 'product-categories', component: ProductCategoriesComponent, canActivate: [AuthGuard] },
          { path: 'product-categories/:id', component: ProductVariantsComponent, data: {permission: 'catalog.view'} },
          { path: 'product-categories/products/:id', component: ProductViewComponent, canActivate: [AuthGuard] },
        ]
      },
      { path: 'quotes', component: QuotesComponent },
      { path: 'quotes/new', component: NewQuoteView },
      { path: 'quotes/item/new', component: NewQuoteItemViewComponent },
      { path: 'quotes/:id', component: QuoteViewComponent },
      { path: 'customers', component: CustomerComponent },
      { path: 'customers/:id', component: CustomerViewComponent },
      { path: 'locations', component: LocationsComponent },
      { path: 'getting-started', component: GettingStartedComponent },
      { path: '', redirectTo: 'quotes', pathMatch: 'full' },
    ]
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
