import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { ProductService } from 'src/app/services/product.service';
import { ModalService } from 'src/app/services/modal.service';
import { CustomizationService } from 'src/app/services/customization.service';
import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
    templateUrl: 'label-picker.component.html',
    selector: 'app-label-picker',
})

export class LabelPickerComponent {
    base64textString: any;
    model: any = {
        image: this.base64textString,
        label: {}
    };
    loading = false;
    currentUser: any;
    pricing: any;
    customizationsToAdd: any = [];
    priceTypes: any = [];
    labelList: any = [];
    currentLabel: any = {};

    constructor(
        public router: Router,
        public userService: UserService,
        public alertService: AlertService,
        public productService: ProductService,
        public modalService: ModalService,
        public customizationService: CustomizationService,
        private message: NzMessageService

    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log(this.currentUser);
    }

    ngOnInit() {
        this.getObjectPricing(this.productService.currentProduct._id);
        this.productService.getCompanyLabels().subscribe(value => {
            this.labelList = value;
            console.log(this.labelList);
        });
    }

    save() {
        this.loading = true;
        this.productService.addLabelJunction(this.productService.currentProduct._id, this.model)
            .subscribe(
                data => {
                    console.log(data);
                    this.alertService.success('Success', true);
                    this.model.label.options.push(this.model.selectOption);
                    this.productService.currentProduct.labels.push(this.model.label);
                    
                    this.loading = false;
                    this.createMessage();
                    this.modalService.closeLabelPicker();
                    // this.productService.g
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    handleFileSelect(evt: any) {
        var files = evt.target.files;
        var file = files[0];

        if (files && file) {
            var reader = new FileReader();

            reader.onload = this._handleReaderLoaded.bind(this);

            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoaded(readerEvt: any) {
        var binaryString = readerEvt.target.result;
        this.base64textString = btoa(binaryString);
        console.log(btoa(binaryString));
    }

    private getObjectPricing(objectId: any) {
        this.productService.getObjectPricing(objectId).subscribe(pricing => {
            this.pricing = pricing;
            console.log(this.pricing);
        });
    }

    checkIfAdded(customization: any) {
        let value = false;
        for (let currentCust of this.productService.currentProduct.customizations) {
            if (currentCust._id == customization._id) {
                return true;
            } else {
                value = false;
            }
        }
        return value;
    }

    toggleCustomization(customization: any) {
        console.log(customization);
        if (customization.isChecked) {
            for (let index in this.customizationsToAdd) {
                let currentCust = this.customizationsToAdd[index];
                if (currentCust._id == customization._id) {
                    this.customizationsToAdd.splice(index, 1);
                    console.log(this.customizationsToAdd);
                    customization.isChecked = false;
                } 
            }
        } else {
            customization.isChecked = true;
            this.customizationsToAdd.push(customization);
            console.log(this.customizationsToAdd);
        }
    }

    addCustomizations() {
        this.productService.addCustomizations(this.productService.currentProduct._id, this.customizationsToAdd).subscribe(value => {
            console.log(value);
        })
    }

    private getPriceTypes() {
        this.productService.getPriceTypes().subscribe(priceTypes => {
            this.priceTypes = priceTypes;
            console.log(priceTypes);
        });
    }

    chooseLabel(label: any) {
        this.model.label = label;
        console.log(this.model.label);
    }

    createMessage(): void {
        this.message.create('success', `Label added successfully`);
    }
}
