import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { ProductService } from 'src/app/services/product.service';
import { ModalService } from 'src/app/services/modal.service';
import { CustomizationService } from 'src/app/services/customization.service';
import { NzMessageService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CompanyService } from 'src/app/services/company.service';


@Component({
    templateUrl: 'media-manager.component.html',
    selector: 'app-media-manager',
})

export class MediaManagerComponent {
    base64textString: any;
    model: any = {
        image: this.base64textString
    };
    loading = false;
    currentUser: any;
    images: any;
    uploading = false;

    selectedImage: any = {};

    customizationsToAdd: any = [];

    constructor(
        public router: Router,
        public userService: UserService,
        public alertService: AlertService,
        public productService: ProductService,
        public modalService: ModalService,
        public customizationService: CustomizationService,
        private message: NzMessageService,
        private notification: NzNotificationService,
        public companyService: CompanyService
    ) {
        // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // console.log(this.currentUser);
    }

    ngOnInit() {
        this.loadAllImages();
        console.log(this.modalService.mediaManagerMode);
    }

    selectImage(image: any) {
        this.selectedImage = image;
    }

    save() {
        this.loading = true;
        this.customizationService.create(this.model)
            .subscribe(
                data => {
                    console.log(data);
                    this.loading = false;
                    this.productService.currentProduct.image[0].dataUrl = data['dataUrl'];
                    this.modalService.closeNewCustomization();
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    handleFileSelect(evt: any) {
        var files = evt.target.files;
        var file = files[0];

        if (files && file) {
            var reader = new FileReader();

            reader.onload = this._handleReaderLoaded.bind(this);

            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoaded(readerEvt: any) {
        this.uploading = true;
        var binaryString = readerEvt.target.result;
        this.base64textString = btoa(binaryString);
        console.log(btoa(binaryString));
        this.uploadImage();
    }

    loadAllImages() {
        this.loading = true;
        return this.productService.getAllImages().subscribe(images => {
            this.images = images;
            console.log(this.images);
            console.log('---------------------- done loading images');
            this.loading = false;
            return true;
        });
    }

    checkIfAdded(customization: any) {
        let value = false;
        for (let currentCust of this.productService.currentProduct.customizations) {
            if (currentCust._id == customization._id) {
                return true;
            } else {
                value = false;
            }
        }
        return value;
    }

    toggleCustomization(customization: any) {
        console.log(customization);
        if (customization.isChecked) {
            for (let index in this.customizationsToAdd) {
                let currentCust = this.customizationsToAdd[index];
                if (currentCust._id == customization._id) {
                    this.customizationsToAdd.splice(index, 1);
                    console.log(this.customizationsToAdd);
                    customization.isChecked = false;
                }
            }
        } else {
            customization.isChecked = true;
            this.customizationsToAdd.push(customization);
            console.log(this.customizationsToAdd);
        }
    }

    addCustomizations() {
        this.productService.addCustomizations(this.productService.currentProduct._id, this.customizationsToAdd).subscribe(value => {
            console.log(value);
        })
    }

    setObjectImage() {
        this.loading = true;

        

        if (this.modalService.mediaManagerMode == "PRODUCT") {
            // this.productService.setImage(this.productService.currentProduct._id, this.selectedImage._id).subscribe(value => {
            //     console.log(value);
            //     this.loading = false;
            //     if (this.productService.currentProduct.image && this.productService.currentProduct.image[0]) {
            //         this.productService.currentProduct.image[0].dataUrl = value['dataUrl'];
            //     } else {
            //         this.productService.currentProduct.image = [
            //             {
            //                 dataUrl: value['dataUrl']
            //             }
            //         ]
            //     }
            //     this.createNotification();
            //     this.modalService.closeMediaManager();
            // });
            this.productService.currentProduct.image[0] = {
                _id: this.selectedImage._id,
                dataUrl: this.selectedImage.dataUrl
            } 
            console.log(this.productService.currentProduct);
            this.modalService.closeMediaManager();
            this.loading = false;
        }

        if (this.modalService.mediaManagerMode == "COMPANY") {
            console.log(this.modalService.mediaManagerMode);
            // this.companyService.setImage(this.userService.currentUser.company._id, this.selectedImage).subscribe(value => {
            //     console.log(value);
            //     this.loading = false;
            //     this.userService.currentUser.company.logo = this.selectedImage;
            //     this.createNotification();
            //     this.modalService.closeMediaManager();
            // });
            this.companyService.docSettingsObject.logo = this.selectedImage;
            console.log(this.companyService.docSettingsObject);
            this.modalService.closeMediaManager();
            this.loading = false;
        }

        if (this.modalService.mediaManagerMode == "TRUSTBADGES") {
            console.log(this.modalService.mediaManagerMode);
            this.companyService.docSettingsObject.trustBadges.push(this.selectedImage);
            console.log(this.companyService.docSettingsObject);
            this.modalService.closeMediaManager();
            this.loading = false;
        }
       
    }

    uploadImage() {
        this.productService.uploadImage(this.base64textString).subscribe(value => {
            this.loadAllImages();
            this.uploading = false;
        });
    }

    createMessage(): void {
        this.message.create('success', `Product image updated`);
    }

    createNotification(): void {
        this.notification.create(
            'success',
            'Product image updated',
            '',
            { nzDuration: 2500 }
        );
    }
}
