import { Component, OnInit } from '@angular/core';

import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
    selector: 'app-admin-sidebar',
    templateUrl: 'admin-sidebar.component.html',
    styleUrls: ['./admin-sidebar.component.scss']

})

export class AdminSidebarComponent implements OnInit {
    currentUser: User;
    users: User[] = [];
    color: '#335eea';
    
    constructor(public userService: UserService, public authService: AuthenticationService, public productService: ProductService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.loadAllUsers();
    }

    deleteUser(_id: string) {
        this.userService.delete(_id).subscribe(() => { this.loadAllUsers() });
    }

    private loadAllUsers() {
        // this.userService.getAll().subscribe(users => { this.users = users; });
    }

    releaseCatalogToMobile() {
        this.productService.releaseCatalogToMobile()
            .subscribe(
                data => {
                    
                },
                error => {
                   
                });
    }
}