import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { NzNotificationService } from 'ng-zorro-antd';


@Component({
    templateUrl: 'account-setup.component.html',
    selector: 'app-account-setup',
    styleUrls: ['./account-setup.component.scss']
})

export class AccountSetupComponent {
    model: any = {};
    loading = false;
    token = '';

    constructor(
        private router: Router,
        private userService: UserService,
        private alertService: AlertService,
        private route: ActivatedRoute,
        private notification: NzNotificationService
        ) { }

    ngOnInit() {
        this.token = this.route.snapshot.paramMap.get("token");
        console.log('Token--------------------------------');
        console.log(this.token);
    }

    changePassword() {
        this.loading = true;
        this.userService.changePassword(this.model, this.token)
            .subscribe(
                data => {
                    this.alertService.success('Password changed successfully', true);
                    this.router.navigate(['/download-apps']);
                    this.createNotificationCreated();
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    createNotificationCreated(): void {
        this.notification.create(
            'success',
            'Password updated',
            '',
            { nzDuration: 2000 }
        );
    }
    
}
