import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { ProductService } from 'src/app/services/product.service';
import { ModalService } from 'src/app/services/modal.service';
import { CustomizationService } from 'src/app/services/customization.service';
import { NzNotificationService } from 'ng-zorro-antd';


@Component({
    templateUrl: 'new-quote-option.component.html',
    selector: 'app-new-quote-option',
})

export class NewQuoteOptionComponent {
    base64textString: any;
    model: any = {
        image: this.base64textString
    };
    loading = false;
    currentUser: any;
    customizationTypes: any = [];
    editing = false;

    constructor(
        public router: Router,
        public userService: UserService,
        public alertService: AlertService,
        public productService: ProductService,
        public modalService: ModalService,
        public customizationService: CustomizationService,
        private notification: NzNotificationService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log(this.currentUser);
    }

    ngOnInit() {
        this.getCustomizationTypes();
        if (this.productService.currentQuoteOption) {
            this.model = this.productService.currentQuoteOption;
            this.editing = true;
        } else {
            this.editing = false;
        }
    };

    save() {
        this.loading = true;
        this.productService.createQuoteOption(this.model)
            .subscribe(
                data => {
                    this.alertService.success('Registration successful', true);
                    this.loading = false;
                    this.router.navigateByUrl('/quote-option/'+data['_id']);
                    this.createNotificationCreated();
                    this.modalService.closeNewQuoteOption();
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    handleFileSelect(evt: any) {
        var files = evt.target.files;
        var file = files[0];

        if (files && file) {
            var reader = new FileReader();

            reader.onload = this._handleReaderLoaded.bind(this);

            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoaded(readerEvt: any) {
        var binaryString = readerEvt.target.result;
        this.base64textString = btoa(binaryString);
        console.log(btoa(binaryString));
    }

    private getCustomizationTypes() {
        this.customizationService.getCustomizationTypes().subscribe(dataObject => {
            this.customizationTypes = dataObject;
            console.log(dataObject);
        });
    }

    createNotificationCreated(): void {
        this.notification.create(
            'success',
            'Quote option created',
            '',
            { nzDuration: 3000 }
        );
    }
}
