import { Component, OnInit } from '@angular/core';

import { User } from '../../models/user';
import { Route, ActivatedRoute } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProductService } from 'src/app/services/product.service';
import { CustomizationService } from 'src/app/services/customization.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
    templateUrl: 'quote-option-view.component.html',
    styleUrls: ['./quote-option-view.component.scss']
})

export class QuoteOptionViewComponent implements OnInit {
    currentUser: User;
    products: any[] = [];
    optionId: any;
    currentOption: any;

    constructor(
        private userService: UserService,
        public modalService: ModalService,
        public productService: ProductService,
        public customizationService: CustomizationService,
        public route: ActivatedRoute,
        private notification: NzNotificationService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.optionId = this.route.snapshot.paramMap.get("id");
        console.log(this.optionId);
        this.loadOption(this.optionId);
    }

    deleteUser(_id: string) {
        this.userService.delete(_id).subscribe(() => { this.loadAllProducts() });
    }

    private loadAllProducts() {
        this.productService.getAll(this.currentUser).subscribe(products => { this.products = products; console.log(this.products); });
    }

    editQuoteOption() {
        this.modalService.openNewQuoteOption();
    }

    loadOption(optionId: any) {
        this.productService.getQuoteOption(optionId).subscribe(value => {
            this.productService.currentQuoteOption = value;
            this.currentOption = value;
            console.log(this.currentOption);
        });
    }

    removeCustomization(customization: any) {
        customization.removing = true;
        this.productService.removeCustomization(this.optionId, customization).subscribe(value => {
            console.log(value);
            this.currentOption.customizations = this.currentOption.customizations.filter(function (value: any) {
                return value._id != customization._id
            });
            this.createNotification();
            customization.removing = false;
        });
    }

    // activateProduct() {
    //     this.productService.activateProduct(this.currentProduct._id).subscribe(value => {
    //         console.log(value);
    //     });
    // }

    // deactivateProduct() {
    //     this.productService.deactivateProduct(this.currentProduct._id).subscribe(value => {
    //         console.log(value);
    //     });
    // }
    
    removeLabel(label: any) {
        this.productService.removeLabel(this.productService.currentProduct._id, label).subscribe(value => {
            console.log(value);
        });
    }

    openPricingEditor(object: any) {
        this.modalService.openPricingEditor(object, 'quoteOption');
    }

    openAddOptions() {
        this.modalService.openAddOptions(this.currentOption);
    }

    createNotification(): void {
        this.notification.create(
            'success',
            'Option removed',
            '',
            { nzDuration: 3000 }
        );
    }
}