import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { appConfig } from '../app.config';
import { User } from '../models/user';

@Injectable()
export class IntegrationService {

    currentProduct: any;
    currentLabel: any;
    currentQuoteOption: any;

    constructor(private http: HttpClient) { }

    getKeapAuthLink() {
        return this.http.get(appConfig.apiUrl + '/integrations/keap/authLink');
    }

    keapAuthCallback(state, code) {
        return this.http.get(appConfig.apiUrl + `/integrations/keap/callback/auth?state=${state}&code=${code}`);
    }

    getCompanyIntegrations() {
        return this.http.get(appConfig.apiUrl + `/integrations/getCompanyIntegrations`);
    }
}