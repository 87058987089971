import { Component, OnInit } from '@angular/core';

import { User } from '../../models/user';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { IntegrationService } from 'src/app/services/integration.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
    templateUrl: 'catalog.component.html',
    styleUrls: ['./catalog.component.scss']
})

export class CatalogComponent implements OnInit {
    currentUser: User;
    users: any[] = [];
    tablePageSize: any = 10;
    tablePageIndex: any;
    listOfDisplayData: any;
    searchTerm: any;
    originalUsers: any[] = [];
    integrations: any;

    constructor(
        public userService: UserService,
        public modalService: ModalService,
        public integrationService: IntegrationService,
        public productService: ProductService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.loadIntegrations();
    }

    deleteUser(_id: string) {
        this.userService.delete(_id).subscribe(() => { this.loadAllUsers() });
    }

    private loadAllUsers() {
        this.userService.getAll(this.currentUser).subscribe(users => { 
            this.originalUsers = users;
            this.users = users; 
        });
    }

    newUser() {
        this.modalService.newUserOpen = true;
    }

    editCompanyInfo() {
        this.modalService.openEditCompanyInfo();
    }

    currentPageDataChange(event) {
        console.log(event);
        this.listOfDisplayData = event;
    }

    currentPageSizeChange(event) {
        this.tablePageSize = event;
    }

    filterItems(searchTerm) {
        this.users = this.originalUsers.filter(item => {
            return item.email.toString().toLowerCase().indexOf(this.searchTerm.toString().toLowerCase()) > -1;
        });
    }

    openIntegrationMenu() {
        this.modalService.openIntegrationMenu();
    }

    loadIntegrations() {
        this.integrationService.getCompanyIntegrations().subscribe(value => {
            console.log(value);
            this.integrations = value;
        });
    }

    releaseCatalogToMobile() {
        this.productService.releaseCatalogToMobile()
            .subscribe(
                data => {
                    
                },
                error => {
                   
                });
    }
}