import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { ProductService } from 'src/app/services/product.service';
import { ModalService } from 'src/app/services/modal.service';
import { NzNotificationService } from 'ng-zorro-antd';


@Component({
    templateUrl: 'new-product-category.component.html',
    selector: 'app-new-product-category',
})

export class NewProductCategoryComponent {
    base64textString: any;
    model: any = {
        image: this.base64textString,

    };
    loading = false;
    currentUser: any;
    imageString: any;

    constructor(
        public router: Router,
        public userService: UserService,
        public alertService: AlertService,
        public productService: ProductService,
        public modalService: ModalService,
        private notification: NzNotificationService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log(this.currentUser);
    }

    ngOnInit() {
        // if (this.modalService.currentCategory) {
        //     this.model = this.modalService.currentCategory;
        // }
    }

    saveLabel() {
        this.loading = true;
        if (this.modalService.currentCategory) {
            this.model = this.modalService.currentCategory;
            this.model.products = [];
        }
        this.productService.saveProductCategory(this.model)
            .subscribe(
                data => {
                    console.log(data);
                    this.alertService.success('label saved', true);
                    this.loading = false;
                    this.router.navigate(['/catalog/product-categories/', data['_id']]);
                    this.modalService.closeNewProductCategory();
                    // this.createNotificationCreated();
                },
                error => {
                    console.log(error);
                    this.loading = false;
                });
    }

    handleFileSelect(evt: any) {
        var files = evt.target.files;
        var file = files[0];

        if (files && file) {
            var reader = new FileReader();

            reader.onload = this._handleReaderLoaded.bind(this);

            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoaded(readerEvt: any) {
        var binaryString = readerEvt.target.result;
        this.base64textString = btoa(binaryString);
        this.base64textString = 'data:image/png;base64,' + this.base64textString;
        console.log(btoa(binaryString));
    }

    cancelNewProduct() {
        this.modalService.newProductOpen = false;
    }

    uploadImage() {
        this.productService.setImage(this.productService.currentProduct._id, this.base64textString).subscribe();
    }

    createNotificationCreated(): void {
        this.notification.create(
            'success',
            'Changes saved',
            '',
            { nzDuration: 3000 }
        );
    }
}
