import { Component } from '@angular/core';
import { ModalService } from './services/modal.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { UserService } from './services/user.service';
import { CompanyService } from './services/company.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0.5, "transform": "translateY(-40px)" }),
        animate('0.2s', style({ opacity: 1, "transform": "translateY(0px)" })),
      ]),
      transition(':leave', [
        animate('1s', style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class AppComponent {
  title = 'briteWebApp2';

  constructor(
    public modalService: ModalService,
    public userService: UserService,
    public companyService: CompanyService

  ) { }

  ngOnInit() {
    this.userService.getCurrentUser().subscribe(value => {
      console.log(value);
      this.userService.currentUser.userObject = value;
      console.log(this.userService.currentUser);
    });
  }
}
