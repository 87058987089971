import { Component, OnInit, HostListener, ElementRef, AfterViewInit, ViewChild, Input } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

import { User } from '../../models/user';
import { ProductService } from '../../services/product.service';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { CompanyService } from 'src/app/services/company.service';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';

@Component({
    templateUrl: 'customers.component.html',
    styleUrls: ['./customers.component.scss'],
    animations: [
        trigger('flyInDown', [
            state('in', style({ transform: 'translateY(0)', opacity: 1.0 })),
            transition('void => *', [
                style({ transform: 'translateY(0px)', opacity: 0.5 }),
                animate(250)
            ])
        ]),
        trigger('flyInUp', [
            state('in', style({ transform: 'translateY(0)', opacity: 1.0 })),
            transition('void => *', [
                style({ transform: 'translateY(0px)', opacity: 0.5 }),
                animate(250)
            ])
        ])
    ]
})


export class CustomerComponent implements OnInit {
    currentUser: User;
    products: any = [];
    originalProducts: any = [];
    sortName: string | null = null;
    sortValue: string | null = null;
    tablePageSize: any = 10;
    tablePageIndex: any;
    searchAddress: string;
    listOfDisplayData: any;
    searching = false;
    formCtrlSub: Subscription;
    searchControl = new FormControl();
    showNavbar = false;
    firstLoading = false;
    pageNumber = 0;
    loadedAllObjects = false;
    fetchingMoreObjects = false;

    searchTerm: any = '';
    searchObject: any = {
        searchTerm: "",
        teamFilter: null
    }

    txtQueryChanged: Subject<string> = new Subject<string>();


    // @ViewChild('input', null) input: ElementRef;

    // @HostListener('window:scroll', ['$event']) // for window scroll events
    // onScroll(event) {
    //     console.log(event);
    //     if (event.srcElement.scrollTop > 11) {
    //         this.showNavbar = true;
    //     }
    //     if (event.srcElement.scrollTop <= 11) {
    //         this.showNavbar = false;
    //     }
    // }

    constructor(
        public userService: UserService,
        public modalService: ModalService,
        public productService: ProductService,
        public companyService: CompanyService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

        this.txtQueryChanged
            .pipe(
                debounceTime(1000), // wait 1 sec after the last event before emitting last event
                distinctUntilChanged() // only emit if value is different from previous value
            )
            .subscribe(model => {
                this.searchTerm = model;

                // Call your function which calls API or do anything you would like do after a lag of 1 sec
                this.searchCustomers(this.searchObject);
            });
    }

    ngOnInit() {
        this.loadAllCustomers();
        this.userService.getCompanyTeamsLocal();
        // this.formCtrlSub = this.searchControl.valueChanges
        //     .debounceTime(500)
        //     .subscribe((newValue) => {
        //         this.searchCustomers(newValue);
        //     });

        // fromEvent(this.input.nativeElement, 'keyup')
        //     .pipe(
        //         debounceTime(500),
        //         distinctUntilChanged(),
        //         tap((text) => {
        //             console.log(this.input.nativeElement.value);
        //             this.searchCustomers(this.input.nativeElement.value);
        //         })
        //     )
        //     .subscribe();
    }

    deleteUser(_id: string) {
        // this.userService.delete(_id).subscribe(() => { this.loadAllProducts() });
    }

    loadAllCustomers() {
        this.searching = true;
        console.log('------------------loadCustomers');
        if (this.companyService.getAllCustomers) {
            this.companyService.getCompanyCustomers(0).subscribe(products => {
                this.originalProducts = products;
                this.products = products;
                this.firstLoading = true;
                this.searching = false;
                console.log(products);
            });
        } else {
            this.companyService.getUserCustomers(this.pageNumber).subscribe(products => {
                this.originalProducts = products['customers'];
                this.products = products['customers'];
                this.firstLoading = true;
                this.searching = false;
                console.log(products);
            });
        }

    }

    newProduct() {
        this.modalService.newProductOpen = true;
    }

    sort(sort: { key: string; value: string }): void {
        console.log(sort);

        if (sort.value == "ascend") {
            this.products.sort(this.compare);
        } else {
            this.products.sort(this.compareDesc);
        }

        console.log(this.products);

        this.listOfDisplayData = this.products.splice(0, this.tablePageSize);
    }

    compare2(a, b) {
        if (a.referenceName < b.referenceName) {
            return -1;
        }
        if (a.referenceName > b.referenceName) {
            return 1;
        }
        return 0;
    }

    compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const bandA = a.updatedAt.toUpperCase();
        const bandB = b.updatedAt.toUpperCase();

        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        return comparison;
    }

    compareDesc(a, b) {
        // Use toUpperCase() to ignore character casing
        const bandA = a.referenceName.toUpperCase();
        const bandB = b.referenceName.toUpperCase();

        let comparison = 0;
        if (bandA < bandB) {
            comparison = 1;
        } else if (bandA > bandB) {
            comparison = -1;
        }
        return comparison;
    }

    currentPageDataChange(event) {
        console.log(event);
        this.listOfDisplayData = event;
    }

    currentPageSizeChange(event) {
        this.tablePageSize = event;
    }

    queryParams(event) {
        console.log(event);
        // this.tablePageSize = event;
    }

    filterItems(searchTerm) {
        this.products = this.originalProducts.filter(item => {
            return item.referenceName.toString().toLowerCase().indexOf(this.searchTerm.toString().toLowerCase()) > -1;
        });
    }

    searchCustomers(searchTerm) {
        this.searching = true;
        this.companyService.searchCompanyCustomers(searchTerm).subscribe(products => {
            this.originalProducts = products;
            this.products = products;
            this.searching = false;
            console.log(products);
        });
        // if (!this.companyService.getAllCustomers) {

        // } else {
        //     this.companyService.searchUserCustomers(searchTerm).subscribe(products => {
        //         this.originalProducts = products;
        //         this.products = products;
        //         this.searching = false;
        //         console.log(products);
        //     });
        // }
    }

    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        if (event.target.scrollHeight - (event.target.scrollTop + event.target.offsetHeight) < 300 && this.fetchingMoreObjects != true) {
            this.fetchingMoreObjects = true;
            this.pageNumber++;

            console.log(event);
            console.log('----------------------------------------Fetch more');

            // this.companyService.get(this.pageNumber).subscribe(products => {
            //     console.log(products);
            //     this.originalProducts = this.originalProducts.concat(products['quotes']);
            //     if (products['quotes'].length == 0) {
            //         this.loadedAllObjects = true;
            //     }
            //     // this.products = products['quotes'];
            //     // this.totalQuoteCount = products['count'];
            //     // this.loading = false;
            //     // console.log(this.products);
            //     this.fetchingMoreObjects = false;
            // });

            this.companyService.getCompanyCustomers(this.pageNumber).subscribe(products => {
                console.log(products);

                this.originalProducts = this.originalProducts.concat(products);
                if (products['length'] == 0) {
                    this.loadedAllObjects = true;
                }
                this.fetchingMoreObjects = false;
            });

        }
    }

    onSearchChange(query: string) {
        this.txtQueryChanged.next(query);
    }

    compareFn = (o1: any, o2: any) => (o1 && o2 ? o1._id === o2._id : o1 === o2);

}