import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

import { FormGroupDirective } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { ProductService } from 'src/app/services/product.service';
import { ModalService } from 'src/app/services/modal.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
    templateUrl: 'new-product.component.html',
    selector: 'app-new-product',
})

export class NewProductComponent {
    base64textString: any;
    model: any = {
        image: this.base64textString
    };
    loading = false;
    currentUser: any;
    imageString: any;
    productCategories: any;
    productTypes: any;

    @ViewChild('f', { static: false }) f: FormGroupDirective;

    constructor(
        private router: Router,
        private userService: UserService,
        private alertService: AlertService,
        public productService: ProductService,
        private modalService: ModalService,
        private message: NzMessageService,
        private notification: NzNotificationService

    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log(this.currentUser);
    }

    ngOnInit() {
        this.productService.getCompanyProductCategories().subscribe(value => {
            this.productCategories = value;
            console.log(this.productCategories)
        });

        this.productService.getProductTypes().subscribe(value => {
            this.productTypes = value;
            console.log(this.productTypes);
        });
    }

    saveProduct() {
        this.loading = true;
        // if (this.productService.currentProduct) {
        //     this.model.id = this.productService.currentProduct;
        // }
        this.productService.create(this.model, this.currentUser)
            .subscribe(
                data => {
                    this.alertService.success('Registration successful', true);
                    this.loading = false;
                    this.modalService.closeNewProduct();
                    console.log(data);
                    this.createNotificationCreated()
                    this.router.navigateByUrl('/product/' + data['_id']);
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    handleFileSelect(evt: any) {
        var files = evt.target.files;
        var file = files[0];

        if (files && file) {
            var reader = new FileReader();

            reader.onload = this._handleReaderLoaded.bind(this);

            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoaded(readerEvt: any) {
        var binaryString = readerEvt.target.result;
        this.base64textString = btoa(binaryString);
        this.base64textString = 'data:image/png;base64,' + this.base64textString;
        console.log(btoa(binaryString));
    }

    cancelNewProduct() {
        this.modalService.newProductOpen = false;
    }

    uploadImage() {
        this.productService.setImage(this.productService.currentProduct._id, this.base64textString).subscribe();
    }

    createMessage(): void {
        this.message.create('success', `Product created`);
    }

    createNotificationCreated(): void {
        this.notification.create(
            'success',
            'Product created',
            '',
            { nzDuration: 3000 }
        );
    }
}
