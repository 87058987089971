import { Component, OnInit } from '@angular/core';
import { CustomizationService } from 'src/app/services/customization.service';

import { User } from '../../models/user';
import { Route, ActivatedRoute, Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProductService } from 'src/app/services/product.service';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';

@Component({
    templateUrl: 'edit-product-view.component.html',
    styleUrls: ['./edit-product-view.component.scss']
})

export class EditProductView implements OnInit {
    currentUser: User;
    products: any[] = [];
    productId: any;
    currentProduct: any = {}
    togglingActiveState = false;
    productCategories: any;
    priceTypes: any = [];
    tierThresholdUnits: any = [];
    savingProduct = false;
    loading = false;

    newTierTemplate: any = {
        min: 1,
        price: 0,
        priceType: null
    }
    model: any = {

    };

    newLabel = {
        displayName: ''
    }

    pagePriceMethod = "STANDARD";

    tieredPricing: any = {
        tierThresholdUnit: {},
        type: "TIERED",
        tiers: [
            {
                max: 1,
                price: 0,
                priceType: {}
            },
            {
                min: 1,
                price: 0,
                priceType: {}
            }
        ]
    }

    constructor(
        private userService: UserService,
        public modalService: ModalService,
        public productService: ProductService,
        public route: ActivatedRoute,
        private message: NzMessageService,
        private notification: NzNotificationService,
        public router: Router

    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        // this.productId = this.route.snapshot.paramMap.get("id");
        // console.log(this.productId);
        // this.loadProduct(this.productId);
        this.productService.currentProduct = {
            image: [],
            displayName: '',
            price: {},
            maxSize: {},
            minSize: {}
        };
        this.productService.getCompanyProductCategories().subscribe(value => {
            this.productCategories = value;
            console.log(this.productCategories)
        });
        this.getPriceTypes();

        this.productId = this.route.snapshot.paramMap.get("id");
        console.log(this.productId);
        this.loadProduct(this.productId);
    }

    deleteUser(_id: string) {
        this.userService.delete(_id).subscribe(() => { this.loadAllProducts() });
    }

    private loadAllProducts() {
        this.productService.getAll(this.currentUser).subscribe(products => { this.products = products; console.log(this.products); });

    }

    editProduct() {
        this.modalService.openEditProduct();
    }

    removeCustomization(customization: any) {
        customization.removing = true;
        this.productService.currentProduct.customizations = this.productService.currentProduct.customizations.filter(function (value: any) {
            return value._id != customization._id
        });
        customization.removing = false;
        // this.createMessageOptionRemoved();
    }

    activateProduct() {
        this.productService.activateProduct(this.currentProduct._id).subscribe(value => {
            console.log(value);
            this.currentProduct.active = true;
            this.togglingActiveState = false;
            this.createNotificationActive();
        });
    }

    deactivateProduct() {
        this.productService.deactivateProduct(this.currentProduct._id).subscribe(value => {
            console.log(value);
            this.currentProduct.active = false;
            this.togglingActiveState = false;
            this.createNotificationInactive();
        });
    }

    openMediaManager() {
        this.modalService.openMediaManager('PRODUCT');
    }

    // removeLabel(label: any) {
    //     label.removing = true;
    //     this.productService.removeLabel(this.productService.currentProduct._id, label).subscribe(value => {
    //         this.productService.currentProduct.labels = this.productService.currentProduct.labels.filter(function (object: any) {
    //             return object._id != label._id
    //         })
    //         label.removing = false;
    //         this.createMessageLabelRemoved();
    //         console.log(value);
    //     });
    // }

    openAddOptions() {
        this.modalService.openAddOptions(this.currentProduct);
    }

    openPricingEditor(object: any) {
        this.modalService.openPricingEditor(object, 'product');
    }

    toggleActiveState() {
        this.togglingActiveState = true;
        if (this.currentProduct.active) {
            this.activateProduct();
        } else {
            this.deactivateProduct();
        }
    }


    createMessageActive(): void {
        this.message.create('success', `Product is now activated`);
    }

    createMessageInactive(): void {
        this.message.create('success', `Product is now deactivated`);
    }

    createMessageOptionRemoved(): void {
        this.message.create('success', `Option removed`);
    }

    createMessageLabelRemoved(): void {
        this.message.create('success', `Label removed`);
    }

    createNotificationActive(): void {
        this.notification.create(
            'success',
            'Product is now activated',
            '',
            { nzDuration: 3000 }
        );
    }

    createNotificationInactive(): void {
        this.notification.create(
            'success',
            'Product is now deactivated',
            '',
            { nzDuration: 3000 }
        );
    }

    saveProductObject() {
        console.log(this.productService.currentProduct);
        this.savingProduct = true;
        this.currentProduct.image = this.productService.currentProduct.image;

        if (this.productService.currentProduct.minSize && this.productService.currentProduct.minSize.value) {
            this.productService.currentProduct.minSize.type = 'ADJUSTCOST';
        }

        this.productService.currentProduct.customizations.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0))
        this.productService.currentProduct.labels.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0))

        if (this.productService.currentProduct.price.type == "TIERED") {
            for (let [index, tier] of this.productService.currentProduct.price.tiers.entries()) {
                if (tier['priceType'].priceType == 'PERCENTAGE') {
                    tier['price'] == tier['price'] / 100;
                }

                if (index != 0) {
                    tier.min = this.productService.currentProduct.price.tiers[index - 1].max;
                }

                tier.sortPosition = index;
            }
        }

        console.log(this.productService.currentProduct);
        console.log(this.currentProduct.image);

        this.productService.saveProductObject(this.productService.currentProduct).subscribe(value => {
            console.log(value);
            this.router.navigate(['/catalog/product-categories/products', value['_id']]);
            this.savingProduct = false;
        });
    }

    getPriceTypes() {
        this.productService.getPriceTypes().subscribe(dataObject => {
            this.priceTypes = dataObject['priceTypes'];
            this.tierThresholdUnits = dataObject['tierThresholdUnits'];
            console.log(dataObject);
        });
    }

    compareFn = (o1: any, o2: any) => (o1 && o2 ? o1._id === o2._id : o1 === o2);

    loadProduct(productId: any) {
        this.loading = true;

        this.productService.getById(productId).subscribe(value => {
            console.log(value);
            this.productService.currentProduct = value;

            if (!this.productService.currentProduct.maxSize) {
                this.productService.currentProduct.maxSize = {};
            }

            if (!this.productService.currentProduct.minSize) {
                this.productService.currentProduct.minSize = {};
            }
            console.log(this.productService.currentProduct);
            this.loading = false;
        });
    }

    // removeCustomization(customization: any) {
    //     console.log(this.productService.currentProduct);
    //     customization.removing = true;
    //     this.productService.removeCustomization(this.productService.currentProduct._id, customization).subscribe(value => {
    //         console.log(value);
    //         this.productService.currentProduct.customizations = this.productService.currentProduct.customizations.filter(function (value: any) {
    //             return value._id != customization._id
    //         });
    //         customization.removing = false;
    //         // this.createMessageOptionRemoved();
    //         console.log(this.productService.currentProduct);
    //     });
    // }

    addTier() {
        this.productService.currentProduct.price.tiers.push(JSON.parse(JSON.stringify(this.newTierTemplate)));
        this.productService.currentProduct.price.tiers[this.productService.currentProduct.price.tiers.length - 2].max = this.productService.currentProduct.price.tiers[this.productService.currentProduct.price.tiers.length - 3].max;
        console.log(this.productService.currentProduct.price.tiers);
    }

    removeLastTier() {
        this.productService.currentProduct.price.tiers.pop();
        this.productService.currentProduct.price.tiers[this.productService.currentProduct.price.tiers.length - 1].max = null;
    }

    changeToTiered() {

        this.productService.currentProduct.price = {
            tierThresholdUnit: this.tierThresholdUnits[0],
            type: "TIERED",
            tiers: [
                {
                    max: 1,
                    price: 0,
                    priceType: null
                },
                {
                    min: 1,
                    price: 0,
                    priceType: null
                }
            ]
        }
    }

    changeToStandard() {

        this.productService.currentProduct.price = {
            type: 'STANDARD'
        }
    }

    addLabel() {
        console.log(this.newLabel)
        if (this.newLabel.displayName.length > 0) {
            if (!this.productService.currentProduct.labels) {
                this.productService.currentProduct.labels = [];
            }

            this.productService.currentProduct.labels.push(this.newLabel);
            this.newLabel = {
                displayName: ''
            }
        }
    }

    removeLabel(label: any) {
        label.removing = true;
        this.productService.currentProduct.labels = this.productService.currentProduct.labels.filter(function (value: any) {
            return value._id != label._id
        });
        label.removing = false;
        // this.createMessageOptionRemoved();
    }
}