import { Component, OnInit } from '@angular/core';

import { User } from '../../models/user';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProductService } from 'src/app/services/product.service';
import { CustomizationService } from 'src/app/services/customization.service';

@Component({
    templateUrl: 'quote-options.component.html'
})

export class QuoteOptionsComponent implements OnInit {
    currentUser: User;
    quoteOptions: any[] = [];
    originalQuoteOptions: any[] = [];
    searchTerm: any = '';
    listOfDisplayData: any;

    constructor(
        private userService: UserService,
        public modalService: ModalService,
        public productService: ProductService,
        public customizationService: CustomizationService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.loadAllQuoteOptions();
        this.productService.currentQuoteOption = null;
    }

    private loadAllQuoteOptions() {
        this.productService.getAllQuoteOptions().subscribe(quoteOptionsReturned => { 
            this.originalQuoteOptions = quoteOptionsReturned; 
            this.quoteOptions = quoteOptionsReturned; 
            console.log(this.quoteOptions); 
        });
    }

    new() {
        this.modalService.openNewQuoteOption();
    }

    filterItems(searchTerm) {
        this.quoteOptions = this.originalQuoteOptions.filter(item => {
            return item.referenceName.toString().toLowerCase().indexOf(this.searchTerm.toString().toLowerCase()) > -1;
        });
    }

    currentPageDataChange(event) {
        console.log(event);
        this.listOfDisplayData = event;
    }
}