import { Component, OnInit } from '@angular/core';

import { User } from '../../models/user';
import { Route, ActivatedRoute, Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProductService } from 'src/app/services/product.service';
import { CustomizationService } from 'src/app/services/customization.service';

@Component({
    templateUrl: 'option-view.component.html',
    styleUrls: ['./option-view.component.scss']
})

export class OptionViewComponent implements OnInit {
    currentUser: User;
    products: any[] = [];
    optionId: any;
    currentOption: any;

    constructor(
        private userService: UserService,
        public modalService: ModalService,
        public productService: ProductService,
        public customizationService: CustomizationService,
        public route: ActivatedRoute,
        public router: Router
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.optionId = this.route.snapshot.paramMap.get("id");
        console.log(this.optionId);
        this.loadOption(this.optionId);
    }

    deleteUser(_id: string) {
        this.userService.delete(_id).subscribe(() => { this.loadAllProducts() });
    }

    private loadAllProducts() {
        this.productService.getAll(this.currentUser).subscribe(products => { this.products = products; console.log(this.products); });
    }

    editCustomization() {
        this.modalService.openNewCustomization(this.currentOption);
    }

    loadOption(optionId: any) {
        this.customizationService.getById(optionId).subscribe(value => {
            this.customizationService.currentOption = value;
            this.currentOption = value;
            console.log(this.currentOption);
        });
    }

    removeCustomization(customization: any) {
        this.productService.removeCustomization(this.productService.currentProduct._id, customization).subscribe(value => {
            console.log(value);
        });
    }

    // activateProduct() {
    //     this.productService.activateProduct(this.currentProduct._id).subscribe(value => {
    //         console.log(value);
    //     });
    // }

    // deactivateProduct() {
    //     this.productService.deactivateProduct(this.currentProduct._id).subscribe(value => {
    //         console.log(value);
    //     });
    // }

    removeLabel(label: any) {
        this.productService.removeLabel(this.productService.currentProduct._id, label).subscribe(value => {
            console.log(value);
        });
    }

    openPricingEditor(object: any) {
        this.modalService.openPricingEditor(object, 'option');
    }

    saveCustomizationObject() {
        this.customizationService.createCustomizationObject(this.currentOption).subscribe(value => {
            console.log(value);
        });
    }

    onBack() {
        this.router.navigate(['catalog/options']);
    }
}