import { Component, OnInit } from '@angular/core';

import { User } from '../../models/user';
import { ProductService } from '../../services/product.service';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
    templateUrl: 'locations.component.html',
    styleUrls: ['./locations.component.scss']
})

export class LocationsComponent implements OnInit {
    currentUser: User;
    products: any[] = [];
    originalProducts: any[] = [];
    sortName: string | null = null;
    sortValue: string | null = null;
    tablePageSize: any = 10;
    tablePageIndex: any;
    searchAddress: string;
    listOfDisplayData: any;

    searchTerm: any = '';

    constructor(private userService: UserService, public modalService: ModalService, public productService: ProductService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.loadAllQuotes();
    }

    deleteUser(_id: string) {
        // this.userService.delete(_id).subscribe(() => { this.loadAllProducts() });
    }

    private loadAllQuotes() {
        this.productService.getAll(this.currentUser).subscribe(products => { 
            this.originalProducts = products;
            this.products = products; 
            console.log(this.products); 
        });

    }

    newProduct() {
        this.modalService.newProductOpen = true;
    }

    sort(sort: { key: string; value: string }): void {
        console.log(sort);

        if (sort.value == "ascend") {
            this.products.sort(this.compare);
        } else {
            this.products.sort(this.compareDesc);
        }

        console.log(this.products);

        this.listOfDisplayData = this.products.splice(0, this.tablePageSize);
    }

    compare2(a, b) {
        if (a.referenceName < b.referenceName) {
            return -1;
        }
        if (a.referenceName > b.referenceName) {
            return 1;
        }
        return 0;
    }

    compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const bandA = a.updatedAt.toUpperCase();
        const bandB = b.updatedAt.toUpperCase();

        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        return comparison;
    }

    compareDesc(a, b) {
        // Use toUpperCase() to ignore character casing
        const bandA = a.referenceName.toUpperCase();
        const bandB = b.referenceName.toUpperCase();

        let comparison = 0;
        if (bandA < bandB) {
            comparison = 1;
        } else if (bandA > bandB) {
            comparison = -1;
        }
        return comparison;
    }

    currentPageDataChange(event) {
        console.log(event);
        this.listOfDisplayData = event;
    }

    currentPageSizeChange(event) {
        this.tablePageSize = event;
    }

    filterItems(searchTerm) {
        this.products = this.originalProducts.filter(item => {
            return item.referenceName.toString().toLowerCase().indexOf(this.searchTerm.toString().toLowerCase()) > -1;
        });
    }

}