import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { appConfig } from '../app.config';
import { User } from '../models/user';

@Injectable()
export class CustomizationService {

    currentOption: any = null;

    constructor(private http: HttpClient) { }

    getAll(currentUser: any) {
        return this.http.get<User[]>(appConfig.apiUrl + '/customizations?user=' + currentUser._id);
    }

    getById(_id: string) {
        return this.http.get(appConfig.apiUrl + '/customizations/getCustomization/' + _id);
    }

    create(customization: any) {
        console.log(customization);
        return this.http.post(appConfig.apiUrl + '/customizations/create', {customization: customization});
    }

    update(user: User) {
        return this.http.put(appConfig.apiUrl + '/users/' + user._id, user);
    }

    delete(_id: string) {
        return this.http.delete(appConfig.apiUrl + '/users/' + _id);
    }

    changePassword(data: any, token: any) {
        return this.http.post(appConfig.apiUrl + '/users/changePassword', {'newPassword': data.newPassword, 'token': token});
    }

    getCustomizationTypes() {
        return this.http.get(appConfig.apiUrl + '/customizations/getCustomizationTypes');
    }

    createCustomizationObject(customization: any) {
        console.log(customization);
        return this.http.post(appConfig.apiUrl + '/customizations/createCustomizationObject', {customizationObject: customization});
    }
}