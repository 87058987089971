import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { ModalService } from 'src/app/services/modal.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { CompanyService } from 'src/app/services/company.service';


@Component({
    templateUrl: 'admin-new-company.component.html',
    selector: 'app-new-company',
})

export class AdminNewCompanyComponent {
    model: any = {
        password: "password"
    };
    loading = false;
    currentUser: any;

    constructor(
        public router: Router,
        public userService: UserService,
        public alertService: AlertService,
        public modalService: ModalService,
        public notification: NzNotificationService,
        public companyService: CompanyService
        ) { 
            this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
            console.log(this.currentUser);
        }

    register() {
        this.loading = true;
        this.companyService.create(this.model)
            .subscribe(
                data => {
                    console.log(data);
                    this.modalService.closeNewCompany();
                    this.createNotification();
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    createNotification(): void {
        this.notification.create(
            'success',
            `Company created`,
            '',
            { nzDuration: 2000 }
        );
    }
}
